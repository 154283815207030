import React from 'react';
import './PlayStoreButton.css';

const PlayStoreButton = () => {
    const openPlayStoreLink = () => {
        const playStoreURL = 'https://play.google.com/store/apps/details?id=com.greendumbells.app';
        window.open(playStoreURL, '_blank');
    };

    return (
        <div className="playbuttoncontainer">
            <div role="button" onClick={openPlayStoreLink}>
                <span className="glow"></span>
                <div>
                    <img
                        className="rounded img-fluid"
                        src="/google-play-icon.png"
                        alt="Google Play Store icon"
                    />
                </div>
            </div>
        </div>
    );
};

export default PlayStoreButton;
