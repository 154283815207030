import MainComponent from "./components/MainComponent";
import ReactGA from "react-ga4";
import "./App.css";
import { SurveyProvider } from "./components/SurveyContext";
import { BrowserRouter } from "react-router-dom";
import "aos/dist/aos.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "swiper/css";
import { AuthenticationContextProvider } from "./services/authentication.context";
import { Notification } from "./shared/Notification";
import { CartContextProvider } from "./services/cart.context";

function App() {
  ReactGA.initialize("G-DKE94QQNSN");
  return (
    <BrowserRouter>
      <SurveyProvider>
        <AuthenticationContextProvider>
          <CartContextProvider>
            <MainComponent />
            <Notification />
          </CartContextProvider>
        </AuthenticationContextProvider>
      </SurveyProvider>
    </BrowserRouter>
  );
}

export default App;
