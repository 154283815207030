import React, { useState, useEffect, useRef } from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Services.css"
import { Helmet } from 'react-helmet'
// import { Services } from "../constants/Services";
import {
  Link,
  useParams,
} from "react-router-dom";
import { Dashurl } from "./Baseurl";
// const data = Services;


export const ServicePage = ({ darkMode }) => {
  const { urlname } = useParams();
  const [lines, setLines] = useState([])
  const [service, setService] = useState({
    tag: "",
    title: "",
    image: "",
    body: "",
    description: "",
    urlname: ""
  });
  // const sf = data.find((s) => s.id === parseInt(id));
  // const { title, image, body } = sf;
  useEffect(() => {
    const fetchService = async () => {
      try {
        const response = await fetch(`${Dashurl}service/${urlname}`);
        if (response.ok) {
          const data = await response.json();
          setService(data);
        } else {
          console.error('Error:', response.status);
        }
      } catch (err) {
        console.error('Error:', err);
      }
    };

    fetchService();
  }, [urlname]);

  useEffect(() => {
    const updatedLines = service.body.split('\\n');
    setLines(updatedLines);
  }, [service.body]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
  }, []);

  let canonicalURL= `https://greendumbells.com/services/${service.urlname}`

  return (
    <>
      <div
        className={
          darkMode ? "darkMode text-center text-light" : "bg-light text-center"
        }
      >
        <Helmet>
          <title>{service.tag} India | GreenDumbells</title>
          <meta name="description" content={service.description} />
          <link rel="canonical" href={canonicalURL} />
        </Helmet>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/services">Services</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{service.title}</BreadcrumbItem>
        </Breadcrumb>
        <h1>{service.title}</h1>
        <p className="lead p-4 text-start" dangerouslySetInnerHTML={{ __html: service.body }}></p>

        <div className="row py-5">
          <img className="col-12 col-md-6 img img-fluid" src={`${Dashurl}` + service.image} alt={service.title} />
          <img className="d-none d-md-block col-12 col-md-6 img img-fluid" src={`${Dashurl}` + service.image} alt={service.title} />
        </div>
      </div>
    </>
  );
};

function ServicesSlickNew({ darkMode }) {
  const [services, setServices] = useState([]);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500, // Adjust the speed as needed (lower value for slower speed)
    autoplay: true,
    slidesToShow: 3, // Display four testimonials on larger screens
    slidesToScroll: 3, // Scroll one testimonial at a time    
    autoplaySpeed: 5000, // Set autoplay speed to 0 to make it continuous
    cssEase: "linear", // Use linear easing for a continuous effect
    pauseOnHover: false, // Do not pause on hover
    rtl: true, // Move from right to left (opposite direction)
    responsive: [
      {
        breakpoint: 768, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 1, // Display two testimonials on smaller screens (mobile)
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await fetch(`${Dashurl}services`);
        if (response.ok) {
          const data = await response.json();
          setServices(data);
        } else {
          console.error("Error:", response.status);
        }
      } catch (err) {
        console.error("Error:", err);
      }
    };

    fetchServices();
  }, []);


  return (
    <>
      <div>
        <h2 className="text-center text-uppercase my-6">
          What Do We Offer For Your Good Health?
        </h2>
        <Slider
          className="body-service"
          {...settings}
        >
          {services.map((item) => (
            <div key={item._id}>
              <Link
                to={`/services/${item.urlname}`}
                style={{ textDecoration: "none", color: "black" }}
              >
                <div className="cards" key={item.id}>
                  <div className="content">
                    <h2>{item.title}</h2>
                    <p dangerouslySetInnerHTML={{ __html: item.body.slice(0, 100) + "..." }}></p>
                    <button className="button">
                      {/* <Link to={`/services/${item.urlname}`} style={{ color: "#000" }}> */}
                        Read More
                      {/* </Link> */}
                    </button>
                  </div>
                  <img src={`${Dashurl}` + item.image} alt={item.title} />
                </div>
              </Link>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
}
export default ServicesSlickNew;
