import React, { createContext, useState, useContext } from 'react';

const SurveyContext = createContext();

export function useSurveyContext() {
  return useContext(SurveyContext);
}

export function SurveyProvider({ children }) {
  const [surveyFormCompleted, setSurveyFormCompleted] = useState(false);

  return (
    <SurveyContext.Provider value={{ surveyFormCompleted, setSurveyFormCompleted }}>
      {children}
    </SurveyContext.Provider>
  );
}
