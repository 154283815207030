import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Dashurl } from "./Baseurl";
import { AuthenticationContext } from "../services/authentication.context";
import { MdVerified } from "react-icons/md";
import dayjs from "dayjs";
import { Rate } from "antd";
import './star.css'
import TextAvatar from "./TextAvatar";
import Swal from "sweetalert2";

const StarRating = ({ rating, onRatingChange }) => {
    const [hover, setHover] = useState(0);

    return (
        <div className="star-rating">
            {[...Array(5)].map((star, index) => {
                const starValue = index + 1;

                return (
                    <button
                        type="button"
                        key={index}
                        className={starValue <= (hover || rating) ? "on" : "off"}
                        onClick={() => onRatingChange(starValue)}
                        onMouseEnter={() => setHover(starValue)}
                        onMouseLeave={() => setHover(0)}
                    >
                        <span className="star">&#9733;</span>
                    </button>
                );
            })}
        </div>
    );
};

const ReviewComponent = ({ productId }) => {
    const [reviews, setReviews] = useState([]);
    const [newRating, setNewRating] = useState({ rating: 0 });
    const [newReview, setNewReview] = useState({ content: "" });
    const { userData } = useContext(AuthenticationContext);
    useEffect(() => {
        axios.get(`${Dashurl}reviews/${productId}`).then((response) => {
            setReviews(response.data);
        });
    }, [productId]);

    const submitReview = () => {
        if (!userData || !userData._id) {
            // Display a warning message or redirect to the login page
            Swal.fire("You need to be logged in to submit a review.");
            return;
        }

        axios
            .post(`${Dashurl}reviews`, { content: newReview.content, rating: newRating.rating, user: userData._id, product: productId })
            .then((response) => {
                axios.get(`${Dashurl}reviews/${productId}`).then((response) => {
                    setReviews(response.data);
                });
                setNewReview({ content: "" });
                setNewRating({ rating: 0 });
            })
            .catch((error) => {
                console.error("Error submitting review:", error);
            });
    };

    const deleteReview = (reviewId) => {
        axios
            .delete(`${Dashurl}reviews/${reviewId}`)
            .then(() => {
                axios.get(`${Dashurl}reviews/${productId}`).then((response) => {
                    setReviews(response.data);
                });
            })
            .catch((error) => {
                console.error("Error deleting review:", error);
            });
    };

    return (
        <div className="container">
            <h3 className="mb-3">Customer Reviews</h3>
            <ul className="list-group">
                {reviews.map((review) => (
                    <li key={review._id} className="list-group-item" style={{ padding: '10px', margin: '10px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', }}>
                            <TextAvatar text={review.user?.name} />
                            <div style={{ marginLeft: '10px' }}>
                                <h5 className="mb-1">{review.user ? (
                                    <>
                                        {review.user.name}
                                        {review.user.isVerified && (
                                            <MdVerified color="green" size={25} />
                                        )}
                                    </>
                                ) : "Unknown User"}</h5>
                                <p>{dayjs(review.createdAt).format("DD-MM-YYYY HH:mm:ss")}</p>
                            </div>
                        </div>


                        <p><Rate allowHalf disabled defaultValue={review.rating} /></p>
                        <p>{review.content}</p>

                        {userData?._id === review.user?._id && (
                            <button className="btn btn-danger" onClick={() => deleteReview(review._id)}>Delete</button>
                        )}
                    </li>
                ))}
            </ul>
            <h3 className="rating-input mt-4">Add a Review</h3>
            <StarRating
                rating={newRating.rating}
                onRatingChange={(selectedRating) => setNewRating({ rating: selectedRating })}
            />
            <textarea
                className="form-control mb-2"
                placeholder="Content"
                value={newReview.content}
                onChange={(e) => setNewReview({ content: e.target.value })}
            />
            <button className="btn btn-primary" onClick={submitReview}>Submit Review</button>
        </div >
    );
};

export default ReviewComponent;
