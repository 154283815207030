import React from 'react'
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import PlayStoreButton from './PlayStoreButton';

function Footer() {
  const moveTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  return (
    <footer className="footer" >
      <div className="container">
        <div className="row">
          <div className="col-12 text-start">
            <h2 className="mb-6 font-weight-bold text-uppercase">
              GreenDumbells
            </h2>
            <h6 className="mb-6">
              Experience The Quality Of Life !!
            </h6>
            <ul className="fw-medium list-unstyled">
              <li className="mb-1">
                <i className="fa fa-envelope fa-lg mr-2"></i>
                <a href="mailto: info@greendumbells.com" className="footerText">
                  info@greendumbells.com
                </a>
              </li>
              <li className="mb-1">
                <i className="fa fa-phone fa-lg mr-2"></i>
                <a href="Tel: 7008809907" className="footerText">
                  +91-7008809907
                </a>
              </li>
              <li>
                <div className="pr-4 mt-3">
                  <a href="https://www.facebook.com/profile.php?id=100087155302295">
                    <i className="footerText mr-2 fa fa-facebook fa-lg"></i>
                  </a>
                  <a href="https://www.instagram.com/green_dumbells/?igshid=YmMyMTA2M2Y%3D">
                    <i className="footerText mx-2 fa fa-instagram fa-lg"></i>
                  </a>
                  <a href="https://www.youtube.com/@greendumbells">
                    <i className="footerText mx-2 fa fa-youtube fa-lg"></i>
                  </a>
                  <a href="https://www.linkedin.com/company/greendumbells/">
                    <i className="footerText mx-2 fa fa-linkedin fa-lg"></i>
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.greendumbells.app">
                    <i className="footerText mx-2 fa fa-android fa-lg"></i>
                  </a>
                  {/* <a href="/" className="footerText mx-2 fa fa-apple fa-lg"></a> */}
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="row text-start">
          <div className="col-6 col-md-3 mt-3">
            <h5 className="text-uppercase">Company</h5>
            <ul className="list-unstyled">
              <li className="mb-1" onClick={moveTop}>
                <Link to="/about-us" className="footerText">
                  About Us
                </Link>
              </li>
              <li className="mb-1" onClick={moveTop}>
                <Link to="/articles" className="footerText">
                  Articles
                </Link>
              </li>
              <li className="mb-1" onClick={moveTop}>
                <Link to="/faqs" className="footerText">
                  FAQs
                </Link>
              </li>
              <li className="mb-1" onClick={moveTop}>
                <Link to="/contact-us" className="footerText">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md-3 mt-3">
            <h5 className="mb-6 text-gray-900 text-uppercase">Services</h5>
            <ul className="list-unstyled">
              <li className="mb-1" onClick={moveTop}>
                <Link to="/services/fatloss" className="footerText">
                  Fatloss
                </Link>
              </li>
              <li className="mb-1" onClick={moveTop}>
                <Link to="/services/nutrition" className="footerText">
                  Nutrition
                </Link>
              </li>
              <li className="mb-1" onClick={moveTop}>
                <Link to="/services/mind-body-balance" className="footerText">
                  Mind-Body Balance
                </Link>
              </li>
              <li className="mb-1" onClick={moveTop}>
                <Link to="/services/lifestyle-guidance" className="footerText">
                  Lifestyle Guidance
                </Link>
              </li>
              <li className="mb-1" onClick={moveTop}>
                <Link to="/services/muscle-gain" className="footerText">
                  Muscle Gain
                </Link>
              </li>
            </ul>
          </div>
          {/* <div className="col-6 col-md-2 mt-3">
            <h5 className="mb-6 text-gray-900 text-uppercase">Tools</h5>
            <ul className="list-unstyled">
              <li className="mb-2">
                <a href="/" className="footerText">
                  BMI Calculator
                </a>
              </li>
              <li className="mb-2">
                <a href="/" className="footerText">
                  Body Fat Calculator
                </a>
              </li>
              <li className="mb-2">
                <a href="/" className="footerText">
                  BMR Calculator
                </a>
              </li>
              <li className="mb-1">
                <a href="/" className="footerText">
                  1RM Calculator
                </a>
              </li>
            </ul>
          </div> */}
          <div className="col-6 col-md-3 mt-3">
            <h5 className="mb-6 text-uppercase">Legal</h5>
            <ul className="list-unstyled">
              <li className="mb-1" onClick={moveTop}>
                <Link to="/privacy-policy" className="footerText">
                  Privacy Policy
                </Link>
              </li>
              <li className="mb-1" onClick={moveTop}>
                <Link to="returns" className="footerText">
                  Return/cancellation policy
                </Link>
              </li>
              <li className="mb-1" onClick={moveTop}>
                <Link to="/terms-and-conditions" className="footerText">
                  Terms &amp; Conditions
                </Link>
              </li>
              <li className="mb-1" onClick={moveTop}>
                <Link to="/delete-data" className="footerText">
                  Delete Data
                </Link>
              </li>
              {/* <li className="mb-1">
                <a href="/" className="footerText">
                  Warranty Policy
                </a>
              </li> */}
            </ul>
          </div>
          <div className="col-6 col-md-3 mt-1  align-self-center">
            <Button
              className="moveTop fw-bold"
              onClick={moveTop}
            >
              <div>
                <i className="fa fa-angle-double-up fa-lg"></i>
              </div>
              <div>UP</div>
            </Button>
            {/* <img
              className="col-6 col-md-3 img-fluid"
              src="/app-store-icon.png"
              alt="Alt text for image"
            /> */}
           <PlayStoreButton/>
          </div>
        </div>
      </div>
      <div className="row col-12">
        <div className="px-4 py-6 mt-4 flex text-center justify-content-between">
          <span className="text-center">
            © 2023{" "}
            <a href="https://GreenDumbells.com/ " className="footerText">
              GreenDumbells
            </a>
            . All Rights Reserved.
          </span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
