import React, { useState, useEffect, useContext } from 'react';
import { Button, Card, Form, Modal } from "react-bootstrap";
import { AuthenticationContext } from '../services/authentication.context';
import { CartContext } from '../services/cart.context';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import "./Address.css";

function Address() {
    const defaultInputProps = {
        error: false,
        errorMessage: "",
        value: "",
    };

    const defaultInputState = {
        name: {
            ...defaultInputProps,
        },
        phone: {
            ...defaultInputProps,
        },
        pincode: {
            ...defaultInputProps,
        },
        state: {
            ...defaultInputProps,
        },
        city: {
            ...defaultInputProps,
        },
        houseNum: {
            ...defaultInputProps,
        },
        area: {
            ...defaultInputProps,
        },
        landmark: {
            ...defaultInputProps,
        },
    };
    const [addresses, setAddresses] = useState([]);
    let { userData } = useContext(AuthenticationContext);
    const { onAddAddress, onRemoveAddress } = useContext(CartContext)
    const [addressModal, setAddressModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [inputs, setInputs] = useState(defaultInputState);
    const [address, setAddress] = useState(null);
    const navigate = useNavigate();
    let addressLen = addresses ? addresses.length : false;


    useEffect(() => {
        if (!userData) {
            navigate("/");
        }
    }, [userData]);

    const onValueChange = (key, value) => {
        setInputs((prevState) => ({
            ...prevState,
            [key]: { value, error: false, errorMessage: '' }
        }));
    };

    const onCloseModal = () => {
        setAddressModal(false);
        setAddress(null);
        resetFormInputs(); // Reset the form inputs when the modal is closed
    };

    const resetFormInputs = () => {
        setInputs({
            name: { value: '', error: false, errorMessage: '' },
            phone: { value: '', error: false, errorMessage: '' },
            pincode: { value: '', error: false, errorMessage: '' },
            state: { value: '', error: false, errorMessage: '' },
            city: { value: '', error: false, errorMessage: '' },
            area: { value: '', error: false, errorMessage: '' },
            houseNum: { value: '', error: false, errorMessage: '' },
            landmark: { value: '', error: false, errorMessage: '' }
        });
    };


    const returnValue = (value) => {
        if (typeof value === "string") {
            return value?.trim();
        } else {
            return value;
        }
    };
    const onSubmit = () => {
        let { name, phone, pincode, state, city, area, houseNum, landmark } =
            inputs;
        name = returnValue(name.value);
        phone = returnValue(phone.value);
        pincode = returnValue(pincode.value);
        state = returnValue(state.value);
        city = returnValue(city.value);
        area = returnValue(area.value);
        houseNum = returnValue(houseNum.value);
        landmark = returnValue(landmark.value);
        let hadErrors = false;
        const setError = (key, message) => {
            setInputs((p) => ({
                ...p,
                [key]: {
                    ...p[key],
                    error: true,
                    errorMessage: message,
                },
            }));
            hadErrors = true;
        };

        if (!name) {
            setError("name", "Name required");
        }
        if (!phone || !/^\d{10}$/.test(phone)) {
            setError("phone", "Invalid mobile number");
        }
        if (!pincode || !/^\d{6}$/.test(pincode)) {
            setError("pincode", "Invalid Pincode");
        }
        if (!state) {
            setError("state", "State required");
        }
        if (!city) {
            setError("city", "City required");
        }
        if (!area) {
            setError("area", "Area required");
        }
        if (!houseNum) {
            setError("houseNum", "House number required");
        }
        if (hadErrors) {
            return;
        }
        let data = {
            name: name,
            phone: Number(phone),
            pincode: Number(pincode),
            state: state,
            city: city,
            area: area,
            houseNum: houseNum,
        };
        if (landmark) {
            data.landmark = landmark;
        }
        setIsLoading(true);

        if (address) {
            data._id = address._id;
            onAddAddress(data, (result) => {
                setIsLoading(false);
                setAddresses(result.address);
                if (userData && userData.address) {
                    userData.address=result.address;
                }
                onCloseModal();
            });
        } else {
            // Add a new address
            onAddAddress(data, (result) => {
                setIsLoading(false);
                setAddresses(result.address);
                if (userData && userData.address) {
                    userData.address=result.address;
                }
                onCloseModal();
            });

        }
    };

    const onEditAddress = (address) => {
        setAddress(address);

        setInputs({
            name: { value: address.name, error: false, errorMessage: '' },
            phone: { value: String(address.phone), error: false, errorMessage: '' },
            pincode: { value: String(address.pincode), error: false, errorMessage: '' },
            state: { value: address.state, error: false, errorMessage: '' },
            city: { value: address.city, error: false, errorMessage: '' },
            area: { value: address.area, error: false, errorMessage: '' },
            houseNum: { value: address.houseNum, error: false, errorMessage: '' },
            landmark: { value: address.landmark || '', error: false, errorMessage: '' }
        });

        setAddressModal(true);
    };

    const onRemove = (address) => {
        Swal.fire({
            title: "Do you want to remove the address?",
            showCancelButton: true,
            confirmButtonText: "Yes",
        }).then((result) => {

            if (result.isConfirmed) {
                setIsLoading(true);
                onRemoveAddress(address._id, () => {
                    setIsLoading(false);
                    setAddresses((prevAddresses) => prevAddresses.filter((a) => a !== address));
                });
            }
        });
    };

    useEffect(() => {
        if (userData && userData.address) {
            setAddresses(userData.address);
        } else {
            setAddresses([]);
        }
    }, [userData]);

    return (
        <div className='bg-light' style={{minHeight: "100vh"}}>
            <Modal size="lg" show={addressModal} onHide={onCloseModal} centered>
                <Modal.Header closeButton className="custom-close-button">
                    <div className="delivery_address">
                        <Modal.Title className="title">Select Delivery Address</Modal.Title>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <div className="delivery-form row" style={{ backgroundImage: 'url("delivery.jpg")', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                        <div className="delivery-form row ">
                            <div className="form-field col-lg-6">
                                <Form.Group>
                                    <Form.Control
                                        className="input-text"
                                        value={inputs.name.value}
                                        onChange={(e) => onValueChange("name", e.target.value)}
                                        type="text"
                                        placeholder="Full Name *"
                                    />
                                    {inputs.name.error && (
                                        <Form.Text className="text-danger">
                                            {inputs.name.errorMessage}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </div>
                            <br />
                            <div className="form-field col-lg-6">
                                <Form.Group>
                                    <Form.Control
                                        className="input-text"
                                        value={inputs.phone.value}
                                        onChange={(e) => onValueChange("phone", e.target.value)}
                                        maxLength={10}
                                        type="text"
                                        placeholder="Phone *"
                                    />
                                    {inputs.phone.error && (
                                        <Form.Text className="text-danger">
                                            {inputs.phone.errorMessage}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </div>
                            <br />
                            <div className="form-field row">
                                <div className="col-sm-12 col-md-4">
                                    <Form.Group>
                                        <Form.Control
                                            className="input-text"
                                            value={inputs.state.value}
                                            onChange={(e) => onValueChange("state", e.target.value)}
                                            type="text"
                                            placeholder="State *"
                                        />
                                        {inputs.state.error && (
                                            <Form.Text className="text-danger">
                                                {inputs.state.errorMessage}
                                            </Form.Text>
                                        )}
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="form-field">
                                <div className="col-sm-12 col-md-4">
                                    <Form.Group>
                                        <Form.Control
                                            className="input-text"
                                            value={inputs.city.value}
                                            onChange={(e) => onValueChange("city", e.target.value)}
                                            type="text"
                                            placeholder="City *"
                                        />
                                        {inputs.city.error && (
                                            <Form.Text className="text-danger">
                                                {inputs.city.errorMessage}
                                            </Form.Text>
                                        )}
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="form-field">
                                <div className="col-sm-12 col-md-4">
                                    <Form.Group>
                                        <Form.Control
                                            className="input-text"
                                            maxLength={6}
                                            value={inputs.pincode.value}
                                            onChange={(e) => onValueChange("pincode", e.target.value)}
                                            type="text"
                                            placeholder="Pincode *"
                                        />
                                        {inputs.pincode.error && (
                                            <Form.Text className="text-danger">
                                                {inputs.pincode.errorMessage}
                                            </Form.Text>
                                        )}
                                    </Form.Group>
                                </div>
                            </div>
                            <br />
                            <div className="form-field">
                                <Form.Group>
                                    <Form.Control
                                        className="input-text"
                                        value={inputs.houseNum.value}
                                        onChange={(e) => onValueChange("houseNum", e.target.value)}
                                        type="text"
                                        placeholder="House No., Build Name *"
                                    />
                                    {inputs.houseNum.error && (
                                        <Form.Text className="text-danger">
                                            {inputs.houseNum.errorMessage}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </div>
                            <br />
                            <div className="form-field">
                                <Form.Group>
                                    <Form.Control
                                        className="input-text"
                                        value={inputs.area.value}
                                        onChange={(e) => onValueChange("area", e.target.value)}
                                        type="text"
                                        placeholder="Road name, Area, Colony *"
                                    />
                                    {inputs.area.error && (
                                        <Form.Text className="text-danger">
                                            {inputs.area.errorMessage}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </div>
                            <br />
                            <div className="form-field">
                                <Form.Group>
                                    <Form.Control
                                        className="input-text"
                                        value={inputs.landmark.value}
                                        onChange={(e) => onValueChange("landmark", e.target.value)}
                                        type="text"
                                        placeholder="Landmark (optional)"
                                    />
                                    {inputs.landmark.error && (
                                        <Form.Text className="text-danger">
                                            {inputs.landmark.errorMessage}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" className="submit-btn" onClick={onSubmit}>
                        Continue
                    </Button>
                    <Button className="submit-btn1" onClick={onCloseModal} style={{ border: "2px solid #000" }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className='head1' >Saved Address</div>
            {!isLoading && addressLen ? (addresses.map((address) => (
                <div className='address-card'>
                    <Card key={address._id} className="mt-2 mb-4">
                        <Card.Body>
                            <Card.Title>
                                Delivery to - {address.name}, {address.pincode}
                            </Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">
                                {address.phone}
                            </Card.Subtitle>
                            <Card.Text>{`${address.houseNum}, ${address.area} ${address.landmark ? ", " + address.landmark : ""
                                }, ${address.city}, ${address.state} -  ${address.pincode}`}</Card.Text>
                            <div className='action-buttons'>
                                <p
                                    onClick={() => onEditAddress(address)}
                                    className='action-button'
                                    style={{
                                        color: "blue",
                                        cursor: "pointer",
                                    }}
                                >
                                    Change Address
                                </p>
                                <p
                                    onClick={() => onRemove(address)}
                                    className='action-button'
                                    style={{
                                        color: "blue",
                                        cursor: "pointer",
                                    }}
                                >
                                    Remove Address
                                </p>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            ))) : (
                <div>
                    <h3 className='text-center m-5'>You have not saved any addresses, kindly add your address!</h3>
                </div>
            )}
            <div className='d-flex align-items-center mx-4'>

                <Button variant="primary" onClick={() => setAddressModal(true)} style={{ width: "10rem", padding: '10px 10px', margin: '10px' }}>
                    Add New Address
                </Button>
                <Button variant="primary" onClick={() => navigate('/cart')} style={{ width: "10rem", padding: '10px 10px', }}>
                    Go to Cart
                </Button>
            </div>


        </div>
    );
}

export default Address;
