import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './styles.css';
import { Dashurl } from './Baseurl';
import { Link } from 'react-router-dom';

const Banner = () => {
  const [bannerData, setBannerData] = useState([]);

  useEffect(() => {
    axios.get(`${Dashurl}banner`)
      .then(response => {
        setBannerData(response.data);
      })
      .catch(error => {
        console.error('Error fetching banner data:', error);
      });
  }, []);

  // If there is no banner data, return null to hide the banner
  if (bannerData.length === 0) {
    return null;
  }

  return (
    
    <div className="banner-container py-5">
      {bannerData.length > 0 && (
        <Link to="/product">
        <div className="custom-banner">
          {bannerData[0].image ? (
            <img src={`${Dashurl}/${bannerData[0].image}`} alt="Banner" className="img-fluid banner-image" />
          ) : bannerData[0].video ? (
            <video loop autoPlay muted playsInline className="img-fluid banner-video" >
              <source src={`${Dashurl}/${bannerData[0].video}`} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : null}
        </div>
        </Link>
      )}
    </div>
  );
};

export default Banner;
