import React, { useState, useEffect, useRef } from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./SalientFeatures.css"
import {
  Link,
  useParams,
} from "react-router-dom";
import { useAppSelector } from "../redux/hooks";
import Header from "../shared/Header";
import { Dashurl } from "./Baseurl";

// const data = salientFeatures;
export const SalientFeaturePage = () => {

  // const sf = data.find((s) => s.id === parseInt(id));
  const { name } = useParams();
  const [sf, setSf] = useState({});


  useEffect(() => {
    const fetchSalientFeatures = async () => {
      try {
        const response = await fetch(`${Dashurl}salientfeatures/${name}`); // Replace 'your_api_endpoint' with your actual API endpoint
        const data = await response.json();
        setSf(data);
      } catch (error) {
        console.error("Error fetching salient features data:", error);
      }
    };
    window.scrollTo({ top: 0, behavior: "smooth" })
    fetchSalientFeatures();
  }, [name]);
  const { author, imageL, imageD, body } = sf;
  const darkMode = useAppSelector((state) => state.darkMode.isDarkMode);

  return (
    <>
      <Header heading="Salient Features" />
      <div
        className={
          darkMode ? "darkMode  text-light pb-4" : "bg-light"
        }
      >
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/">Salient Features</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{author}</BreadcrumbItem>
        </Breadcrumb>
        <h1 className="fw-bold text-uppercase text-center">{author}</h1>
        <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
          <img
            className="img img-fluid"
            src={`${Dashurl}${imageD}`}
            alt={author}
            style={{ maxWidth: '100%', height: 'auto', left: '52%' }}
          />
        </div>

        <p className="lead py-4 justify-content-start mx-5" dangerouslySetInnerHTML={{ __html: body }}></p>
      </div>
    </>
  );
};

function SalientFeaturesComponent({ darkMode }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchSalientFeatures = async () => {
      try {
        const response = await fetch(`${Dashurl}silentfeatures`);
        const data = await response.json();
        setData(data);
      } catch (error) {
        console.error("Error fetching salient features data:", error);
      }
    };
    fetchSalientFeatures();
  }, []);


  const CustomPrevArrow = (props) => (
    <div
      className="custom-arrow custom-prev-arrow"
      onClick={props.onClick}
      style={{ left: "10px", zIndex: 1 }}
    >
      &lt;
    </div>
  );

  const CustomNextArrow = (props) => (
    <div
      className="custom-arrow custom-next-arrow"
      onClick={props.onClick}
      style={{ right: "10px", zIndex: 1 }}
    >
      &gt;
    </div>
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 3,
    prevArrow: <CustomPrevArrow />, // Use your custom prev arrow component
    nextArrow: <CustomNextArrow />, // Use your custom next arrow component
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  return (
    <div>
      <h2 className="text-center text-uppercase">
        GreenDumbells' Salient Features
      </h2>
      <p> </p>
      <div className="row col-12">
        <Slider {...settings}>
          {data.map((item) => (
            <div key={item._id} >
              <div className="container-drop">
                <div className="drop">
                  <div className="content">
                    <img
                      src={`${Dashurl}` + item.imageL}
                      alt={item._id}
                    />
                    <h3><Link to={`/salientfeatures/${item._id}`} style={{ color: "black" }}>{item.author}</Link></h3>
                    <p dangerouslySetInnerHTML={{ __html: item.body.slice(0, 100) + "..." }}></p>
                    <button
                      className="button"
                    ><Link to={`/salientfeatures/${item.urlname}`} style={{ color: "#000" }}>Read More</Link></button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default SalientFeaturesComponent;
