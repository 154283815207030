import axios from "axios";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { showNotification } from "../shared/Notification";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { getLocalStorage, removeLocalStorage } from "../utility/helper";

// if refresh token expired
function onLogout(navigate) {
  Swal.fire({
    title: "Your session is expired! please login to continue",
    showCancelButton: true,
    confirmButtonText: "Login",
    confirmButtonColor: "var(--primary)",
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    // if (result.isConfirmed) {
    removeLocalStorage("loggedIn");
    navigate("/");
    window.location.reload();
    // }
  });
}

const useHttp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sendRequest = useCallback(
    async (
      requestConfig,
      callbacks = {
        successCallback: () => null,
        errorCallback: () => null,
      },
      loader = false,
      notify = true
    ) => {
      let type = requestConfig.type;
      let url = requestConfig.url;
      let data = requestConfig.data;
      let token = getLocalStorage("token");
      let headers = {
        "Access-Control-Allow-Origin": "*",
        authorization: token,
        ...requestConfig.headers,
      };

      try {
        let request;
        if (!type || type === "GET") {
          request = axios.get(url, { headers: headers });
        } else if (type && type === "POST") {
          request = axios.post(url, data, {
            headers: headers,
            withCredentials: true,
          });
        } else if (type && type === "PUT") {
          request = axios.put(url, data, { headers: headers });
        } else if (type && type === "DELETE") {
          request = axios.delete(url, { headers: headers });
        } else {
          showNotification({
            status: "error",
            message: "Invalid http call request",
          });
          return;
        }
        request
          .then((res) => {
            callbacks.successCallback(res.data);
            if (res.data && res.data.message) {
              notify &&
                showNotification({
                  status: "success",
                  message: res.data.message,
                });
            }
          })
          .catch(async (err) => {
            callbacks.errorCallback &&
              callbacks.errorCallback(err.response?.data);
            console.log(err, "error in http call");
            let message;
            if (
              err.response &&
              err.response.data &&
              (err.response.data.accessToken === null ||
                err.response.data.refreshToken === null)
            ) {
              return onLogout(navigate);
            } else if (
              err.response &&
              err.response.data &&
              err.response.data.message
            ) {
              message = err.response.data.message;
            } else if (err.response && err.response.statusText) {
              message = err?.response?.data?.error || err.response.statusText;
            } else {
              message = "Error in http call request";
            }
            notify &&
              showNotification({
                status: "error",
                message: message,
              });
          });
      } catch (err) {
        console.log(err);
        callbacks.errorCallback && callbacks.errorCallback();
        notify && showNotification({ status: "error", message: err });
      }
    },
    [dispatch, navigate]
  );

  return {
    sendRequest,
  };
};

export default useHttp;
