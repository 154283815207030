import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  UncontrolledAccordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import { Link } from "react-router-dom";
// import { FAQs } from "../constants/Faq";
import ReactGA from "react-ga4";
import { Dashurl } from "../components/Baseurl";
import Popup from "../components/Popup";
import {motion} from "framer-motion"
import { Helmet } from 'react-helmet'

interface faq {
  _id: string;
  question: string;
  answer: string;
}
function FaqComponent(props: any) {
  const [faqs, setFaqs] = useState<faq[]>([]);
  useEffect(() => {
    const fetchfaqs = async () => {
      try {
        const response = await fetch(`${Dashurl}faqs`);
        if (response.ok) {
          const data = await response.json();
          setFaqs(data);
        } else {
          console.error("Error:", response.status);
        }
      } catch (err) {
        console.error("Error:", err);
      }
    };

    fetchfaqs();
  }, []);
  ReactGA.send({ hitType: "pageview", page: "/faqs" });
  return (
    <>
      {/* <Popup/> */}
    <div
      className={props.darkMode ? "text-light bg-dark" : "text-dark bg-light"}>
        <Helmet>
        <title>FAQs | Greendumbells</title>
        <meta name="description" content="Get fit and stay healthy with greendumbells, the best home workout fitness and diet app. Achieve your fitness goals with personalized workouts and nutrition plans." />
        <link rel="canonical" href=" https://greendumbells.com/faqs" />
      </Helmet>
      <div className="container">
        <div className="row">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>FAQs</BreadcrumbItem>
          </Breadcrumb>
          <div className="col-12">
            <h2>Frequently Asked Questions</h2>
          </div>
        </div>

        <div className="row row-content">
          {faqs.map((faq: any) => (
            <div key={faq._id} className="col-12 col-md-9">
              <UncontrolledAccordion
                className="col-sm-12 col-md-8 m-2"
                key={faq._id}
                defaultOpen="0"
              >
                <AccordionItem>
                  <AccordionHeader targetId={faq._id}>
                    {faq.question}
                  </AccordionHeader>
                  <AccordionBody accordionId={faq._id}>
                    <p>{faq.answer}</p>
                  </AccordionBody>
                </AccordionItem>
              </UncontrolledAccordion>
            </div>
          ))}
        </div>
      </div>
    </div>
    </>
  );
}

export default FaqComponent;
