import React from 'react';
import { Link } from 'react-router-dom';
import "./AppContent.css";


const AppContent = () => {
    return (
        <div className="homepage1">
            <div className="video1">
                <video width="100%" height="660" loop autoPlay muted playsInline>
                    <source src="/AppVideo.mp4" frameBorder="0" allowFullScreen type="video/mp4" />
                </video>
            </div>
            <div className="contents1" style={{ textAlign: 'justify' }}>
                <p>Looking for the ultimate fitness app in India? Look no further than Greendumbells! <strong>Our HOME WORKOUT FITNESS AND DIET APP</strong> is your all-in-one solution, whether you're a seasoned fitness enthusiast or just starting your health journey.</p>
                <Link to="/read-more" className="read-more-button">Read More</Link>
                <div class="arrow1"></div>
            </div>
        </div>
    );
};

export default AppContent;
