import { createSlice, configureStore } from '@reduxjs/toolkit';
import { InitialFeedback } from './forms';
import { createForms } from 'react-redux-form';
const initialState = {
  user: null,
  isLoading: false,
  isDarkMode: false
};

const darkModeSlice = createSlice({
  name: 'darkMode',
  initialState,
  reducers: {
    toggleDarkMode: state => {
      state.isDarkMode = !state.isDarkMode;
    }
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginStart: (state) => {
      state.isLoading = true;
    },
    loginSuccess: (state, action) => {
      state.isLoading = false;
      state.user = action.payload;
    },
    logout: (state) => {
      state.user = null;
    },
  },
});

const store = configureStore({
  reducer: {
    darkMode: darkModeSlice.reducer,
    auth: authSlice.reducer,
    ...createForms({
      feedback: InitialFeedback
  })
  }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export const { loginStart, loginSuccess, logout } = authSlice.actions;
export const { toggleDarkMode } = darkModeSlice.actions;
export const selectUser = (state:RootState) => state.auth.user;
export default store;
