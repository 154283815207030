import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import NavBar from "../shared/NavBar";
import Footer from "../shared/Footer";
import HomeComponent from "../screens/HomeComponent";
import AboutComponent from "../screens/AboutComponent";
import { useAppSelector } from "../redux/hooks";
import ArticlesComponent from "../screens/ArticlesComponent";
import { ArticlePage } from "../screens/ArticlesComponent";
import ServicesComponent from "../screens/ServicesComponent";
import Contact from "../screens/ContactComponent";
import { SalientFeaturePage } from "./SalientFeaturesComponent";
import { ServicePage } from "./ServicesSlickNew";
import FaqComponent from "../screens/FaqComponent";
import Privacy from "../screens/Privacy";
import Return from "../screens/Return";
import Terms from "../screens/Terms";
import TextLine from "../shared/TextLine";
import { motion, AnimatePresence } from "framer-motion";
import Popup from "../components/Popup";
import DeleteData from "../screens/DeleteData";
import Preloader from "./PreLoader";
import ProductComponent from "../screens/ProductComponent";
import ReadMoreContent from "./ReadMoreContent";
import ProductDetailsComponent from "../screens/ProductDetailsComponent";
import Login from "../screens/Login";
import CartComponent from "../screens/Cart";
import NotFound from "../screens/NotFound";
import Spinner from "./Spinner";
import Address from "../screens/Address";
import Orders from "../screens/Orders";

function MainComponent() {
  const isDarkMode: Boolean = useAppSelector(
    (state) => state.darkMode.isDarkMode
  );
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const animationVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
      transition: {
        duration: 1, // Duration of the appearance animation
        delay: 0.5, // Delay before content starts appearing
      },
    },
    out: {
      opacity: 0,
    },
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false); // Set isLoading to false after a delay (you can replace this with your actual loading logic)
    }, 3000); // Adjust the delay as needed
  }, []);

  return (
    <>
      {/* {isLoading ? (
        <Preloader isLoading={isLoading} />
      ) : ( */}
        <div
          style={
            isDarkMode
              ? {
                backgroundImage: "url(1BL.png)",
                backgroundPosition: "center",
                backgroundSize: "cover",
                height: "120vh",
              }
              : {
                backgroundImage: "url(Landing.jpg)",
                backgroundPosition: "center",
                backgroundSize: "cover",
                height: "120vh",
              }
          }
        >
          <TextLine />
          <NavBar auth="" />
          <AnimatePresence>
            <motion.div
              key={location.pathname}
              initial="initial"
              animate="in"
              exit="out"
              variants={animationVariants}
              transition={{ type: "tween", duration: 0.5 }}
            >
              <Routes location={location}>
                <Route path="/" element={<HomeComponent />} />
                <Route path="/spinner" element={<Spinner />} />
                <Route path="/login" element={<Login />} />
                <Route path="/about-us" element={<AboutComponent />} />
                <Route path="/services" element={<ServicesComponent />} />
                <Route path="/articles/" element={<ArticlesComponent />} />
                <Route path="/articles/:name" element={<ArticlePage />} />
                <Route path="/product/" element={<ProductComponent />} />
                <Route path="/cart/" element={<CartComponent />} />
                <Route
                  path="/product/:productId/"
                  element={<ProductDetailsComponent />}
                />
                <Route path="/read-more" element={<ReadMoreContent />} />
                <Route
                  path="/services/:urlname"
                  element={<ServicePage darkMode={isDarkMode} />}
                />
                <Route
                  path="/salientfeatures/:name"
                  element={<SalientFeaturePage />}
                />
                <Route
                  path="/contact-us"
                  element={<Contact darkMode={isDarkMode} />}
                />
                <Route
                  path="/faqs"
                  element={<FaqComponent darkMode={isDarkMode} />}
                />
                <Route
                  path="/privacy-policy"
                  element={<Privacy darkMode={isDarkMode} />}
                />
                <Route
                  path="/returns"
                  element={<Return darkMode={isDarkMode} />}
                />
                <Route
                  path="/terms-and-conditions"
                  element={<Terms darkMode={isDarkMode} />}
                />
                <Route
                  path="/delete-data"
                  element={<DeleteData darkMode={isDarkMode} />}
                />
                <Route
                  path="/address"
                  element={<Address />}
                />
                <Route
                  path="/orders"
                  element={<Orders />}
                />
                <Route
                  path="*"
                  element={<NotFound />}
                />
              </Routes>
              {["/", "/about-us", "/services", "/articles", "/faqs"].includes(location.pathname) && <Popup />}
            </motion.div>
          </AnimatePresence>
          <Footer />
        </div>
      {/* )} */}
    </>
  );
}

export default MainComponent;
