import React, { useState, useEffect } from 'react'
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";
// import { Services } from '../constants/Services';
import Header from '../shared/Header';
import ReactGA from 'react-ga4'
import { Dashurl } from '../components/Baseurl';
import Popup from '../components/Popup';
import { motion } from "framer-motion"
import { Helmet } from "react-helmet"
interface Service {
  _id: string;
  title: string;
  image: string;
  body: string;
  urlname: string;
}
function ServicesComponent() {

  const [services, setServices] = useState<Service[]>([]);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await fetch(`${Dashurl}services`);
        if (response.ok) {
          const data = await response.json();
          setServices(data);
        } else {
          console.error("Error:", response.status);
        }
      } catch (err) {
        console.error("Error:", err);
      }
    };

    fetchServices();
  }, []);

  ReactGA.send({ hitType: "pageview", page: "/services" });
  const darkMode: any = useAppSelector((state) => state.darkMode.isDarkMode);
  return (
    <>
      <Popup />
      <Helmet>
        <title>Services We Provide | Greendumbells</title>
        <meta name="description" content="Get fit and stay healthy with greendumbells, the best home workout fitness and diet app. Achieve your fitness goals with personalized workouts and nutrition plans." />
        <link rel="canonical" href=" https://greendumbells.com/services" />
      </Helmet>
      <Header heading="Services" />
      <div className={darkMode ? "bg-dark text-light p-5" : "bg-light p-5"}      >
        <div className="dark:text-white">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link className={darkMode ? "text-light" : "text-dark"} style={{ textDecoration: "none" }} to="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active className={darkMode ? "text-light" : "text-dark"}>Services</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div >
          {services && services.length > 0 ? (
            services.map((service) => (
              <Link
                to={`/services/${service.urlname}`}
                className={darkMode ? "text-light" : "text-dark"}
                style={{ textDecoration: "none" }}
                key={service._id}
              >
                <div className="row text-center">
                  <h1 className="text-uppercase my-5 col-12">{service.title}</h1>
                  <p className="p-3 col-12 col-md-6 align-self-center" dangerouslySetInnerHTML={{ __html: service.body && service.body.slice(0, 250) + "..." }}></p>
                  <img className="col-12 col-md-6" src={`${Dashurl}` + service.image} alt="muscle" />
                </div>
              </Link>
            ))
          ) : (
            <p>No services found.</p>
          )}
        </div>
      </div>

    </>
  )
}

export default ServicesComponent