import { Link } from 'react-router-dom';
import { Button } from 'antd';
// import { articles } from '../constants/Articles';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect } from "react";
import { Dashurl } from './Baseurl';

// const data = articles
interface Article {
  _id: string;
  urlname: string;
  title: string;
  image1: string;
  image2: string;
  content: string;
  date: string;
  __v: number;
}
export const Blogs = () => {

  const [articles, setArticles] = useState<Article[]>([]);


  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await fetch(`${Dashurl}articles`);
        if (response.ok) {
          const data = await response.json();
          setArticles(data);
        } else {
          console.error("Error:", response.status);
        }
      } catch (err) {
        console.error("Error:", err);
      }
    };

    fetchArticles();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  return <div  className="row col-12">
    <div>
      <h2
        className="text-center text-uppercase my-6"
      >
        Read our expert blog
      </h2>
      <div>
        <Slider {...settings}>
          {articles.map((item) => (
            <div key={item._id} className="blog-container">
              <div className="card">
                <div className="img-box">
                  <img
                    className="img img-fluid p-2"
                    src={`${Dashurl}` + item.image1}
                    alt={item.title}
                  />
                </div>
                <div className="content">
                  <h2 style={{ textTransform: 'uppercase', color: 'white' }} className='pb-3'>{item.title}</h2>
                  <p className="text-start"  dangerouslySetInnerHTML={{ __html: item.content.slice(0, 150) + "..." }}></p>
                  <Button className="read-btn" ><Link to={`/articles/${item.urlname}`}>Read More</Link></Button>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  </div>;
};