/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useEffect, useState } from "react";

import { Dashurl } from "../components/Baseurl";
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from "../utility/helper";
import useHttp from "../hooks/useHttp";

export const AuthenticationContext = createContext({
  userData: null,
  loggedIn: false,
  onSignin: (data, callback, errorCallBack, loader, notify) => null,
  onsetVerifiedUser: (data, callback, errorCallBack, loader, notify) => null,
  onLogout: (callback) => null,
  onGetUsers: (callback, loader, notify) => null,
  onSetUserData: (data) => null,
  onGoogleSigin: (data) => null
});

export const AuthenticationContextProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const { sendRequest } = useHttp();

  useEffect(() => {
    (async () => {
      let loggedIn = getLocalStorage("loggedIn");
      if (loggedIn) {
        setLoggedIn(true);
        onGetSelfUser(
          async (result) => {
            onSetUserData(result.user);
          },
          false,
          false
        );
      }
    })();
  }, []);

  const onSignInSuccess = async (data) => {
    setLocalStorage("loggedIn", true);
    setLocalStorage("token", data.token);
    setLoggedIn(true);
    setUserData(data.user);
  };

  const onSignin = async (
    data,
    callback,
    errorCallBack = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: Dashurl + `signin`,
        type: "POST",
        data: data,
      },
      {
        successCallback: async (result) => {
          await onSignInSuccess(result);
          callback();
        },
        errorCallback: errorCallBack,
      },
      loader,
      notify
    );
  };

  const onGoogleSigin = async (data) => {
    await onSignInSuccess(data);
  }

  const onSetUserData = (data) => {
    setUserData(data);
  };

  const onLogout = async (callback = () => { }) => {
    setUserData(null);
    setLoggedIn(false);
    removeLocalStorage("loggedIn");
    removeLocalStorage("token");
    callback();
  };

  const onGetSelfUser = async (
    callback = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: Dashurl + "user",
      },
      {
        successCallback: callback,
      },
      loader,
      notify
    );
  };

  const onsetVerifiedUser = async (
    callback = () => null,
    errorCallBack = () => null,
    loader = true,
    notify = true
  ) => {
    console.log(userData._id)
    sendRequest(
      {
        url: Dashurl + `isverified/${userData._id}`,
        type: 'POST',
      },
      {
        successCallback: callback,
        errorCallback: errorCallBack,
      },
      loader,
      notify
    );
  };

  return (
    <AuthenticationContext.Provider
      value={{
        userData,
        loggedIn,
        onSignin,
        onLogout,
        onSetUserData,
        onGetSelfUser,
        onGoogleSigin,
        onsetVerifiedUser
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};
