import React, { useEffect, useState } from 'react';
import './styles.css';
import MultiPageForm from './MultiPageForm';
import { Modal } from 'reactstrap';
import { useSurveyContext } from './SurveyContext';
import {motion} from 'framer-motion'

const Popup = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { surveyFormCompleted, setSurveyFormCompleted } = useSurveyContext();

  useEffect(() => {
    const popupTimer = setTimeout(() => {
      setShowPopup(true);
    }, 5000);

    return () => {
      clearTimeout(popupTimer);
    };
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleAnotherButtonClick = () => {
    setModalOpen(true);
  };

  const handleSurveyFormCompleted = () => {
    setShowPopup(false);
    setSurveyFormCompleted(true);
  };

  const buttonVariants = {
    initial: {
      opacity: 2,
      scale: 1,
    },
    animate: {
      opacity: 1,
      scale: [1, 1.05, 1],
      transition: {
        duration: 2,  // Total duration of the loop
        repeat: Infinity,  // Infinite loop
      },
    },   
  };

  var animateButton = function(e) {

    e.preventDefault()
    //reset animation
    e.target.classList.remove('animate');
    
    e.target.classList.add('animate');
    setTimeout(function(){
      e.target.classList.remove('animate');
    },700);
  };
  
  var bubblyButtons = document.getElementsByClassName("bubbly-button");
  
  for (var i = 0; i < bubblyButtons.length; i++) {
    bubblyButtons[i].addEventListener('click', animateButton, false);
  }
  return (
    <>
      {showPopup && !surveyFormCompleted && (
        <div className="popup-container">
          <div className="popup-overlay">
            <div className="popup-content">
              <span className="close-icon" onClick={closePopup}>
                &times;
              </span>
              <p className='formComplete font-weight-bold'>Get Your Customised Exercise plan For Free !!</p>
              <div className='d-flex flex-column'>
                <motion.button className='btn bubbly-button btn-custom-other' onClick={handleAnotherButtonClick}
                 variants={buttonVariants}
                 initial="initial"
                 animate="animate"                 
                >
                  Fill Our Survey Form                  
                </motion.button>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
        <MultiPageForm modalOpen={modalOpen} setModalOpen={setModalOpen} onSurveyFormCompleted={handleSurveyFormCompleted} />
      </Modal>
    </>
  );
};

export default Popup;