const currencyFormatter = new Intl.NumberFormat("en-In", {
  style: "currency",
  currency: "INR",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const currencyFormatterDefault = new Intl.NumberFormat("en-In", {
  style: "currency",
  currency: "INR",
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

export function getCurrencyLocaleString(price) {
  if (Number.isInteger(price)) {
    return currencyFormatterDefault.format(price).replace("₹", "₹ ");
  }
  let converted = currencyFormatter.format(price);
  return converted.replace("₹", "₹ ");
}

export const setLocalStorage = (name, data) => {
  localStorage.setItem(name, data);
};

export const getLocalStorage = (name) => {
  var data = localStorage.getItem(name);
  return data;
};

export const removeLocalStorage = (name) => {
  localStorage.removeItem(name);
};
