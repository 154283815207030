import React from 'react'
import GdBlobAnimate from "../components/GdBlobAnimate";
import { useAppSelector } from "../redux/hooks";
import PlayStoreButton from './PlayStoreButton';

const Header = ({heading}:any) => {
    const darkMode = useAppSelector((state) => state.darkMode.isDarkMode);
    return (
      <div className={darkMode ? "text-light" : ""}>
        <div className="row col-12 content-evenly">
          <div className="align-self-center text-center col-12 col-md-6">
            <div className="display-4 font-weight-bold text-uppercase">
              {heading}
            </div>
            <div className="d-md-none">
              
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 500 500"
                width="100%"
                id="blobSvg1"
                transform="rotate(0)"
                style={{ opacity: 1 }}
              >
                <image
                  x="0"
                  y="0"
                  width="100%"
                  height="100%"
                  clipPath="url(#shape1)"
                  href="https://greendumbells.com/gdmainlogoCrop.png"
                  preserveAspectRatio="none"
                />{" "}
                <defs>
                  {" "}
                  <linearGradient
                    id="gradient1"
                    x1="0%"
                    y1="0%"
                    x2="0%"
                    y2="100%"
                  >
                    {" "}
                  </linearGradient>{" "}
                </defs>{" "}
                <clipPath id="shape1">
                  <path id="blob" fill="url(#gradient)">
                    {" "}
                    <animate
                      attributeName="d"
                      dur="10000ms"
                      repeatCount="indefinite"
                      values="M440.5,320.5Q418,391,355.5,442.5Q293,494,226,450.5Q159,407,99,367Q39,327,31.5,247.5Q24,168,89,125.5Q154,83,219.5,68Q285,53,335.5,94.5Q386,136,424.5,193Q463,250,440.5,320.5Z;M453.78747,319.98894Q416.97789,389.97789,353.96683,436.87838Q290.95577,483.77887,223.95577,447.43366Q156.95577,411.08845,105.64373,365.97789Q54.33169,320.86732,62.67444,252.61056Q71.01719,184.3538,113.01965,135.21007Q155.02211,86.06634,220.52211,66.46683Q286.02211,46.86732,335.5,91.94472Q384.97789,137.02211,437.78747,193.51106Q490.59704,250,453.78747,319.98894Z;M411.39826,313.90633Q402.59677,377.81265,342.92059,407.63957Q283.24442,437.46649,215.13648,432.5428Q147.02853,427.61911,82.23325,380.9572Q17.43796,334.29529,20.45223,250.83809Q23.46649,167.38089,82.5856,115.05707Q141.70471,62.73325,212.19045,63.73015Q282.67618,64.72705,352.67308,84.79839Q422.66998,104.86972,421.43486,177.43486Q420.19974,250,411.39826,313.90633Z;M440.5,320.5Q418,391,355.5,442.5Q293,494,226,450.5Q159,407,99,367Q39,327,31.5,247.5Q24,168,89,125.5Q154,83,219.5,68Q285,53,335.5,94.5Q386,136,424.5,193Q463,250,440.5,320.5Z;"
                    ></animate>{" "}
                  </path>
                </clipPath>
              </svg>
            </div>
            <div className="p-4 mt-3 ms-6">
              <a href="https://www.facebook.com/profile.php?id=100087155302295"><i className="text-success footerText mx-2 fa fa-facebook fa-lg"></i></a>
              <a href="https://www.instagram.com/green_dumbells/?igshid=YmMyMTA2M2Y%3D"><i className="text-success footerText mx-2 fa fa-instagram fa-lg"></i></a>
              <a href="https://www.youtube.com/@greendumbells"><i className="footerText text-success mx-2 fa fa-youtube fa-lg"></i></a>
              <a href="https://www.linkedin.com/company/greendumbells/"><i className="footerText text-success mx-2 fa fa-linkedin fa-lg"></i></a>
              <a href="https://play.google.com/store/apps/details?id=com.greendumbells.app"><i className="text-success footerText mx-2 fa fa-android fa-lg"></i></a>
              {/* <a href="/" className="text-success mx-2 fa fa-apple fa-lg"></a> */}
            </div>
            <div className='mb-4'>
              <h4 className="text-gray-600 text-2xl font-bold p-3">
                {" "}
                Get Our{" "}
                <p className="text-success text-uppercase d-inline">
                  feature packed{" "}
                </p>{" "}
                App
              </h4>
              {/* <img
                className="col-6 col-md-3 img-fluid"
                src="/app-store-icon.png"
                alt="Alt text for image"
              /> */}
              <PlayStoreButton/>
            </div>
          </div>
          <div className="d-none d-md-block col-md-6">
            <GdBlobAnimate />
          </div>
        </div>
      </div>
    );
  };

export default Header