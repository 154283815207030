import React, { useState } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button, Fade, FormFeedback } from 'reactstrap';
import axios from 'axios'
import { Baseurl, Dashurl } from '../components/Baseurl';
import ReactGA from 'react-ga4'
import Popup from '../components/Popup';
import { motion } from "framer-motion"
// import "../styles.css"
import { Helmet } from 'react-helmet'
import Swal from 'sweetalert2';

const Contact = ({ darkMode }) => {
  ReactGA.send({ hitType: "pageview", page: "/contactus" });
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [feedback, setFeedback] = useState('');
  const [phone, setPhone] = useState('');
  const [canWeConnect, setCanWeConnect] = useState(false);
  const [timeslot, setTimeslot] = useState('not selected');
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const errors = {};
    if (!name.trim()) {
      errors.name = 'Name is required';
    }
    if (!phone.trim()) {
      errors.phone = 'Contact number is required';
    } else if (!validatePhoneNumber(phone)) {
      errors.phone = 'Contact number must be of 10 digits'
    }
    if (!email.trim()) {
      errors.email = 'Email is required';
    } else if (!isValidEmail(email)) {
      errors.email = 'Invalid email format';
    }
    if (canWeConnect) {
      if (!timeslot.trim()) {
        errors.timeslot = 'Timeslot is required';
      }
    }
    if (!feedback.trim()) {
      errors.feedback = 'feedback is required';
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const isValidEmail = (email) => {
    // Basic email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const validatePhoneNumber = (value) => {
    const phoneNumberRegex = /^\d{10}$/;
    if (!phoneNumberRegex.test(value)) {
      return false;
    }
    return true; // Validation successful
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      const configuration = {
        method: "post",
        url: `${Dashurl}contactform`,
        data: { name, phone, email, feedback, canWeConnect, timeslot }
      };
      axios(configuration)
        .then((result) => {
          Swal.fire("Thank you, we have received your message!")
          // alert("Thank you, we have received your message!")
        })
        .catch((error) => {
          error = new Error();
          // alert("Error, please check your internet connection!")
          Swal.fire("Error, please check your internet connection!");
        });

      // Add your form submission logic here

      // Clear form fields
      setName('');
      setEmail('');
      setFeedback('');
      setPhone('');
      setTimeslot('');
      setCanWeConnect(false);
      setErrors({});
    };
  }
  var animateButton = function (e) {

    e.preventDefault()
    //reset animation
    e.target.classList.remove('animate');

    e.target.classList.add('animate');
    setTimeout(function () {
      e.target.classList.remove('animate');
    }, 700);
  };

  var bubblyButtons = document.getElementsByClassName("bubbly-button");

  for (var i = 0; i < bubblyButtons.length; i++) {
    bubblyButtons[i].addEventListener('click', animateButton, false);
  }


  return (

    <div className={darkMode ? "text-light" : "text-dark"} >
      {/* <Popup/> */}
      <Helmet>
        <title>Contact Us | Greendumbells</title>
        <meta name="description" content="Get fit and stay healthy with greendumbells, the best home workout fitness and diet app. Achieve your fitness goals with personalized workouts and nutrition plans." />
        <link rel="canonical" href=" https://greendumbells.com/contact-us" />
      </Helmet>
      <Container >
        <Row>
          <Col sm="12" md={{ size: 6, offset: 1 }}>
            <h2 className='font-weight-bold'>Contact Us</h2>
            <Form>
              <FormGroup>
                <Label className={darkMode ? "text-light font-weight-bold" : "text-dark font-weight-bold"} for="name">Name</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Enter your name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  invalid={errors.name ? true : false}
                />
                {errors.name && <FormFeedback>{errors.name}</FormFeedback>}
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col>
                    <Label className={darkMode ? "text-light font-weight-bold" : "text-dark font-weight-bold"} for="phone">Phone Number</Label>
                    <Input
                      type="text"
                      name="phone"
                      id="phone"
                      placeholder="Enter your contact number"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      invalid={errors.phone ? true : false}
                    />
                    {errors.phone && <FormFeedback>{errors.phone}</FormFeedback>}
                  </Col>
                  <Col>
                    <Label className={darkMode ? "text-light font-weight-bold" : "text-dark font-weight-bold"} for="email">Email</Label>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      invalid={errors.email ? true : false}
                    />
                    {errors.email && <FormFeedback>{errors.email}</FormFeedback>}
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row className='ml-2'>
                  <Col md={{ size: 6 }}>
                    <Input type='checkbox' name="canWeConnect" id='canWeConnect' onChange={(e) => setCanWeConnect(!canWeConnect)} />
                    <strong>May we contact you?</strong>
                  </Col><Col>
                    <Fade in={canWeConnect}>
                      <Label className='font-weight-bold' htmlFor="contactTime">
                        <strong>Time-slot</strong>
                      </Label>
                    </Fade>
                    <Fade in={canWeConnect}>
                      <Input
                        type='select'
                        id="timeslot"
                        name="timeslot"
                        className="form-control"
                        value={timeslot}
                        onChange={(e) => setTimeslot(e.target.value)}
                        invalid={errors.timeslot ? true : false}
                      >
                        <option value="not selected">Select a timeslot</option>
                        <option value="9am to 11am">9am - 11am</option>
                        <option value="11am to 3pm">11am - 3pm</option>
                        <option value="3pm to 6pm">3pm - 6pm</option>
                        <option value="6pm to 9pm">6pm - 9pm</option>
                      </Input>
                      {errors.subject && <FormFeedback>{errors.subject}</FormFeedback>}
                    </Fade>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Label className={darkMode ? "text-light font-weight-bold" : "text-dark font-weight-bold"} for="feedback">Feedback/Query</Label>
                <Input
                  type="textarea"
                  name="feedback"
                  id="feedback"
                  placeholder="Enter your feedback/query"
                  rows={5}
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                  invalid={errors.feedback ? true : false}
                />
                {errors.message && <FormFeedback>{errors.message}</FormFeedback>}
              </FormGroup>
              <div className="pb-5">
                {/* <Button color="primary" type="submit">Submit</Button> */}
                <button className="bubbly-button" type="submit" onClick={handleSubmit}>Submit</button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contact;
