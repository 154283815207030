import React from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";
import Header from "../shared/Header";
import ReactGA from 'react-ga4'
import Popup from "../components/Popup";
import {motion} from "framer-motion"
import {Helmet} from "react-helmet"

function AboutComponent() {
  ReactGA.send({hitType: "pageview", page:"/aboutus"});
  const darkMode = useAppSelector((state) => state.darkMode.isDarkMode);

  return (
    <>
    {/* <Popup/> */}
    <Helmet>
        <title>About Us | Greendumbells</title>
        <meta name="description" content="Get fit and stay healthy with greendumbells, the best home workout fitness and diet app. Achieve your fitness goals with personalized workouts and nutrition plans." />
        <link rel="canonical" href=" https://greendumbells.com/about-us" />
      </Helmet>
    <Header heading="About Us" />  
      <div className={darkMode ? "bg-dark text-light col-12" : "bg-light col-12"}>
        <div className="p-5">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link className= {darkMode ? "text-light" : ""} to="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active className= {darkMode ? "text-light" : ""}>About Us</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div >
          <div className="row text-center">
            <h1 className="text-uppercase my-5 col-12">
              Allow us to introduce ourselves
            </h1>
            <p className="p-3 col-12 col-md-6  align-self-center">
              {" "}
              We understand that every individual has a different body type,
              habits, and requirements, which is why we created such a wonderful
              and unique community that is the best fit for everyone to keep
              getting inspired and motivated.
            </p>
            <img className="col-12 col-md-6" src="/seminar.jpg" alt="us"/>
          </div>
          <div className="row text-center">
            <h1 className="text-uppercase my-5 col-12">our mission</h1>
            <img className="col-12 col-md-6" src="/manrunning.jpg" alt="mission"/>
            <p className="col-12 col-md-6 align-self-center p-3">
              {" "}
              We hope to give our customers a different perspective of being
              fit. Fitness is seen as a tedious task which is a repeated cycle
              of just tasteless diet and muscle cramping exercises. But we want
              to change that for anyone who is interested in our services. We
              want to help you see it as a part of your life. We are here to
              help you achieve your fitness goals both mentally and physically.
            </p>
          </div>
          <div className="text-center row text-center">
            <h1 className="text-uppercase my-5 col-12">our core values</h1>
            <p className="align-self-center p-3 col-12 col-md-6">
              {" "}
              We focus on making fitness flexible and more inclusive with one’s
              day to day life. Our diet plans are guided by the principles of
              ayurveda and any updated research on human health. We make it our
              priority to ensure your home workouts are more active and
              engaging. We make sure our services are budget-friendly and
              effective in your busy life.
            </p>
            <img className="col-12 col-md-6 p-2" src="/betterEarth.jpg" alt="value" />
          </div>
        </div>
        </div>
      
    </>
  );
}

export default AboutComponent;
