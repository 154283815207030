import React, { useState, useEffect } from "react";
import {
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
  Button,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import {
  Link,
  useParams,
  Navigate,
} from "react-router-dom";
// import { articles } from "../constants/Articles";
import { useAppSelector } from "../redux/hooks";
import Header from "../shared/Header";
import ReactGA from 'react-ga4'
import { Dashurl } from "../components/Baseurl";
import Popup from "../components/Popup";
import "../components/style.css";
import { Helmet } from 'react-helmet'

ReactGA.send({ hitType: "pageview", page: "/articles" });
const ArticleCard = ({ article }) => {
  const [redirect, setRedirect] = useState(false);
  const handleClick = () => {
    setRedirect(true);
  };

  if (redirect) {
    return <Navigate to={`/articles/${article.urlname}`} />;
  }

  const { title, image1, content } = article;

  return (
    <>
      <Card>
        <div className="l-container">
          <div className="b-game-card">
            <div className="b-game-card__cover">
              <CardImg top src={`${Dashurl}` + image1} alt={title} />
            </div>
          </div>
        </div>
        <CardBody>
          <CardTitle tag="h5">{title}</CardTitle>
          <p className="text-start" dangerouslySetInnerHTML={{ __html: content.slice(0, 80) + "..." }}></p>
          <Link to={`/articles/${article.urlname}`}><Button>Read More</Button></Link>
        </CardBody>
      </Card>
    </>
  );
};

export const ArticlePage = () => {
  const { name } = useParams();
  // const article = articles.find((a) => a.id === parseInt(id));

  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await fetch(`${Dashurl}articles/name/${name}`);
        if (response.ok) {
          const data = await response.json();
          setArticles(data);
        } else {
          console.error("Error:", response.status);
        }
      } catch (err) {
        console.error("Error:", err);
      }
    };
    window.scrollTo({ top: 0, behavior: "smooth" })
    fetchArticles();
  }, []);

  const { title, image1, image2, content } = articles;
  const darkMode = useAppSelector((state) => state.darkMode.isDarkMode);
  return (
    <>
      {/* <Header /> */}
      <div
        className={darkMode ? "bg-dark text-center text-light p-3" : "bg-light text-center p-3"}

      >
        {/* <Popup/> */}
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/articles">Articles</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{title}</BreadcrumbItem>
        </Breadcrumb>
        <h1 style={{ fontWeight: "bold" }}>{title}</h1>
        <div className="row space-between">
          {/* <img
            className="col-12 col-md-6 img img-fluid"
            src={`${Dashurl}`+image1}
            alt={title}
          />                   */}
          <div className="d-flex justify-content-center align-items-center">
            <img
              className="col-md-6 img img-fluid"
              src={`${Dashurl}${image2}`}
              alt={title}
            />
          </div>

        </div>
        <p className="lead p-4 text-start" dangerouslySetInnerHTML={{ __html: content }}></p>
      </div>
    </>
  );
};

const ArticlesComponent = () => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await fetch(`${Dashurl}articles`);
        if (response.ok) {
          const data = await response.json();
          setArticles(data);
        } else {
          console.error("Error:", response.status);
        }
      } catch (err) {
        console.error("Error:", err);
      }
    };

    fetchArticles();
  }, []);
  const darkMode = useAppSelector((state) => state.darkMode.isDarkMode);
  return (
    <>
      <Helmet>
        <title>Articles | Greendumbells</title>
        <meta name="description" content="Get fit and stay healthy with greendumbells, the best home workout fitness and diet app. Achieve your fitness goals with personalized workouts and nutrition plans." />
        <link rel="canonical" href=" https://greendumbells.com/articles" />
      </Helmet>
      <Popup />
      <Header heading="Articles" />
      <div className={darkMode ? "bg-dark" : "bg-light"}>
        <div className="container p-5">
          <div className="">
            <Breadcrumb>
              <BreadcrumbItem>
                <Link className={darkMode ? "text-light" : ""} to="/">Home</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active className={darkMode ? "text-light" : ""}>Articles</BreadcrumbItem>
            </Breadcrumb>
          </div>
          <div className="row">
            {articles.map((article) => (
              <div className="col-md-4 mb-3" key={article._id}>
                <ArticleCard article={article} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ArticlesComponent;
