import React, { useState, useEffect, useContext } from 'react'
import axios from "axios";
import { Dashurl } from "../components/Baseurl";
import { AuthenticationContext } from "../services/authentication.context";
import { Button, Card, Form, Modal, Spinner } from "react-bootstrap";
import { getCurrencyLocaleString } from "../utility/helper";
import "../components/Orders.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';

function Orders() {
    const [orders, setOrders] = useState([]);
    let orderslen = orders?orders.length:false;
    const [payments, setPayments] = useState([]);
    const { userData } = useContext(AuthenticationContext);
    useEffect(() => {
        function getOrders() {
            axios.get(`${Dashurl}users/${userData._id}`).then((response) => {
                setOrders(response.data.orders);
            });
        }
        if (!orders.length) getOrders();
    }, [])
    useEffect(() => {
        function getPayments() {
            axios.get(`${Dashurl}payment`).then((res) => {
                setPayments(res.data)
            })
            const updatedOrders = orders.map(order => {
                const payment = payments.find(payment => payment._id === order.payment);
                return {
                    ...order,
                    payment: payment // Replace payment ID with the payment object
                };
            });
            setOrders(updatedOrders);
        }
        getPayments();
    }, [orders.length])

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className='vh-125 bg-light'>
            <div className='head-order ml-5'>My Orders</div>
            <div className="row col-12">
                {orderslen ? orders.reverse().map((order, ind) => (
                    <div key={ind} className='order-card fade-in col-12 col-md-4'>
                        <Card className="mt-2 mb-4">
                            <Card.Body className='order-details'>
                                <Card.Title>
                                    <div className='order-title'>
                                        Order ID: {order.order_id}
                                    </div>
                                    <div className='order-date'>
                                        Order Date: {order.createdAt.slice(0, 10)}
                                    </div>
                                </Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">
                                    Amount Paid: {order.payment && order.payment.prices ? getCurrencyLocaleString(order.payment.prices.total) : null} <br />
                                    Delivery Address: {`${order.address.houseNum}, ${order.address.area} ${order.address.landmark ? ", " + order.address.landmark : ""
                                        }, ${order.address.city}, ${order.address.state} -  ${order.address.pincode}`}
                                </Card.Subtitle>
                                {order.items.map((item, id) => (
                                    <div key={id} className='row'>
                                        <img
                                            className="img img-fluid col-3"
                                            src={item.images[0]}
                                            alt={item.name}
                                        />
                                        <p className='col-9'>{item.name} <br />
                                            Quantity Ordered: {item.orderQuantity}<br/>
                                            Price: {getCurrencyLocaleString(item.finalPrice)} <br />
                                            Discount: {item.discount}%
                                        </p>

                                    </div>
                                ))}
                            </Card.Body>
                        </Card>
                    </div>
                )) : (
                <div className='row d-flex align-items-center text-center'>
                    <Link to="/product" className='col-6 col-md-6' >
                        <img src="popup.png" alt='New product' style={{"marginLeft": -100}} />
                    </Link>
                    <h3 className='col-12 col-md-6'>You have not made any purchases, Try out our Organic Cold Pressed Mustard Oil!</h3>
                </div>)}
            </div>
        </div>
    )
}

export default Orders