import { useState, useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';
import { AuthenticationContext } from "../services/authentication.context";
import { Dashurl } from "./Baseurl";

export default function Spinner() {
    const { onGoogleSigin } = useContext(AuthenticationContext);

    //const [user, setUser] = useState();
    //const [token, setToken] = useState("none");
    const navigate = useNavigate();

    useEffect(() => {
        const getUser = async () => {
            console.log("use effect in spinner");
            try {
                const response = await fetch(`${Dashurl}auth/login/success`, {
                    method: "GET",
                    credentials: "include",
                });
                if (response.status === 200) {
                    const resObject = await response.json();
                    // setUser(resObject.user);
                    //setToken(resObject.token);
                    // console.log(resObject)
                    onGoogleSigin(resObject);
                    // localStorage.setItem("token", JSON.stringify(resObject.user.token));
                } else {
                    throw new Error("Authentication has failed!");
                }
            } catch (err) {
                console.log(err);
            }
        };

        getUser();
        const redirectTimeout = setTimeout(() => {
            const storedToken = localStorage.getItem("token");
            if (storedToken === "none") {
                navigate("/login");
            } else {
                navigate("/");
            }
        }, 500);

        return () => {
            clearTimeout(redirectTimeout);
        };

    }, [navigate]);
    // console.log("user => ", user);
    // console.log("token => ", token);


    return (
        <>
            <div className="spinner">
                {/* <h1>Redirecting you..</h1> */}
                {/* <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="grey"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                /> */}
            </div>

        </>
    );
}