import React, { useState } from 'react';
import axios from 'axios';
import { Baseurl, Dashurl } from './Baseurl';
import './style.css';

const MultiPageForm = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [emailValidationError, setEmailValidationError] = useState('');
  const [phoneValidationError, setPhoneValidationError] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [genderWarning, setGenderWarning] = useState('');
  const [foodHabitsWarning, setFoodHabitsWarning] = useState('');
  const [bodyTargetWarning, setBodyTargetWarning] = useState('');
  const [activitiesWarning, setActivitiesWarning] = useState('');
  const [frequencyWarning, setFrequencyWarning] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    age: '',
    height: '',
    weight: '',
    gender: '',
    foodHabits: [],
    bodyTarget: [],
    activities: [],
    frequency: '',
    email: '',
    phone: '',
    ageValidationError: '', // Validation error state for age
    heightValidationError: '', // Validation error state for height
    weightValidationError: '', // Validation error state for weight
  });

  const nextPage = () => {
    // Check if the required options are selected before proceeding
    if (currentPage === 1) {
      if (!formData.name || !formData.age || !formData.height || !formData.weight) {
        setFormData((prevData) => ({
          ...prevData,
          ageValidationError: !formData.age ? "We serve people between the ages 18-60" : "",
          heightValidationError: !formData.height ? "Kindly enter a height that is possible for an adult" : "",
          weightValidationError: !formData.weight ? "Kindly enter a weight that is possible for an adult" : "",
        }));
        return;
      }
    } else if (currentPage === 2) {
      if (!formData.gender || !formData.frequency) {
        // Display warning for gender and frequency questions
        if (!formData.gender) {
          setGenderWarning('Response Required');
        } else {
          setGenderWarning('');
        }
        if (!formData.frequency) {
          setFrequencyWarning('Response Required');
        } else {
          setFrequencyWarning('');
        }
        return;
      }
    } else if (currentPage === 3) {
      if (!formData.foodHabits.length) {
        // Display warning for food habits question
        setFoodHabitsWarning('Response Required');
        return;
      } else {
        setFoodHabitsWarning(''); // Clear the warning when the option is selected
      }
    } else if (currentPage === 4) {
      if (!formData.bodyTarget.length) {
        // Display warning for body target question
        setBodyTargetWarning('Response Required');
        return;
      } else {
        setBodyTargetWarning(''); // Clear the warning when the option is selected
      }
    } else if (currentPage === 5) {
      if (!formData.activities.length) {
        // Display warning for activities question
        setActivitiesWarning('Response Required');
        return;
      } else {
        setActivitiesWarning(''); // Clear the warning when the option is selected
      }
    }
    // If all fields are filled, proceed to the next page
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setEmailValidationError('Invalid email format');
      return;
    } else {
      // Clear the emailValidationError when the email is valid
      setEmailValidationError('');
    }

    // Phone number validation (assuming 10-digit numeric phone number)
    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(formData.phone)) {
      setPhoneValidationError('Invalid phone number format');
      return;
    }

    try {
      const response = await axios.post(`${Dashurl}GDform`, formData);
      console.log(response.data);
      setFormSubmitted(true); // This will log the response from the server
    } catch (error) {
      console.error(error);
    }
  };

  const handleFoodHabitsChange = (value, ischecked) => {
    // Create a new array with selected food habits
    const updatedFoodHabits = [...formData.foodHabits];

  if (ischecked) {
    // If the checkbox is checked, add the value to the array
    updatedFoodHabits.push(value);
  } else {
    // If the checkbox is unchecked, remove the value from the array
    const index = updatedFoodHabits.indexOf(value);
    if (index !== -1) {
      updatedFoodHabits.splice(index, 1);
    }
  }

  // Update the formData with the updated array
  setFormData({
    ...formData,
    foodHabits: updatedFoodHabits,
  });
};

  const handleBodyTargetChange = (value, ischecked) => {
    // Create a new array with selected body target areas
    const updatedBodyTargets = [...formData.bodyTarget];
    if (ischecked) {
      updatedBodyTargets.push(value);
    } else {
      const index = updatedBodyTargets.indexOf(value);
      if (index !== -1) {
        updatedBodyTargets.splice(index,1);
      }
    }

    setFormData({
      ...formData,
      bodyTarget: updatedBodyTargets,
    });
  };

  const handleActivitiesChange = (value, ischecked) => {
    // Create a new array with selected activities
    const updatedActivities = [...formData.activities];
    if (ischecked) {
      updatedActivities.push(value);
    } else {
      const index = updatedActivities.indexOf(value);
      if (index !== -1) {
        updatedActivities.splice(index, 1);
      }
    }
  
    setFormData({
      ...formData,
      activities: updatedActivities, // Update the activities property, not bodyTarget
    });
  };
  


  const handleInputChange = (field, value) => {
    let errorMessage = ''; // Initialize error message to an empty string

    if (field === 'age') {
      // Age validation
      const ageRegex = /^(1[8-9]|[2-5][0-9]|60)$/; // Age should be between 18 and 60
      if (!ageRegex.test(value)) {
        errorMessage = "We serve people between the ages 18-60";
      }
    } else if (field === 'height') {
      // Height validation
      const heightRegex = /^(9[0-9]|1\d{2}|2[0-4]\d)$/; // Valid height range
      if (!heightRegex.test(value)) {
        errorMessage = "Kindly enter a height that is possible for an adult";
      }
    } else if (field === 'weight') {
      // Weight validation
      const weightRegex = /^(2[5-9]|[3-9][0-9]|1\d{2}|2[0-4]\d)$/; // Valid weight range
      if (!weightRegex.test(value)) {
        errorMessage = "Kindly enter a weight that is possible for an adult";
      }
    }
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
      [`${field}ValidationError`]: errorMessage,
    }));
  };


  const renderFormPage = () => {
    if (currentPage === 0) {
      return (
        <div className="body-survey">
          <div className='box'>
            <div className="form">
              <form id="surveyForm">
                <h3>Hey! We would like to know you personally before customizing your diet and workout plan. Kindly answer our questions.</h3>

                {currentPage === 0 && (
                  <button className='button2' type="button" onClick={() => setCurrentPage(1)} style={{
                    marginTop: '50px',
                    marginLeft: 'auto',
                    display: 'block',
                    marginRight: 'auto',
                    width: '140px', // Adjust the width as needed
                    height: '55px', // Adjust the height as needed
                    fontSize: '18px', // Adjust the font size as needed
                  }}>Let's begin</button>
                )}
              </form>
            </div>
          </div>
        </div>
      );
    } else if (currentPage === 1) {
      return (
        <div className="body-survey" style={{ height: '600px' }}>
          <div className="box" style={{ height: '600px' }}>
            <div className="form" style={{ height: '596px' }}>
              <div style={{ marginTop: "10px" }}>
                <div className="inputBox">
                  <span>What is your name?</span>
                  <input type="text" required="required" value={formData.name}
                    onChange={(e) => handleInputChange('name', e.target.value)} />
                  <i></i>
                </div>
                <div className="inputBox">
                  <span>What is your age?</span>
                  <input type="text" required="required" pattern="\d+"
                    value={formData.age}
                    onChange={(e) => handleInputChange('age', e.target.value)} />
                  <i></i>
                  <p className="warning">{formData.ageValidationError}</p>
                </div>
                <div className="inputBox">
                  <span>What is your height (in cm)?</span>
                  <input type="text" required="required" value={formData.height}
                    onChange={(e) => handleInputChange('height', e.target.value)} />
                  <i></i>
                  <p className="warning">{formData.heightValidationError}</p>
                </div>
                <div className="inputBox">
                  <span>What is your weight (in kg)?</span>
                  <input type="text" required="required" value={formData.weight}
                    onChange={(e) => handleInputChange('weight', e.target.value)} />
                  <i></i>
                  <p className="warning">{formData.weightValidationError}</p>
                </div>
              </div>
              <div className="body-btn center">
                <div className="buttons" style={{ marginTop: '10px' }}>
                  <button className='button2' type="button" onClick={prevPage}>Previous</button>
                  <button className='button3' type="button" onClick={nextPage}>Next</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (currentPage === 2) {
      return (
        <div className="body-survey">
          <div className="box">
            <div className="form" >
              <div class="container" style={{ marginTop: '-50px' }}>
                
                <h4>What is your gender?</h4>
                <p className="warning-p">{genderWarning}</p>
                <div>
                  <input type="checkbox" id="male" name="gender" value="Male"
                    checked={formData.gender === 'Male'} // Check if this option is selected in formData
                    onChange={(e) => handleInputChange('gender', e.target.value)} />
                  <label htmlFor="male">Male</label>
                  <div>
                  </div>
                  <input type="checkbox" id="female" name="gender" value="Female"
                    checked={formData.gender === 'Female'} // Check if this option is selected in formData
                    onChange={(e) => handleInputChange('gender', e.target.value)} />
                  <label htmlFor="female">Female</label>
                  <div>
                  </div>
                  <input type="checkbox" id="others" name="gender" value="Others"
                    checked={formData.gender === 'Others'} // Check if this option is selected in formData
                    onChange={(e) => handleInputChange('gender', e.target.value)} />
                  <label htmlFor="others">Others</label>
                </div>
              </div>
              
              <div style={{ marginLeft: '10px' }}>
                <h4>How often do you exercise?</h4>
                <p className="warning-p">{frequencyWarning}</p>
                <div>
                  <input type="checkbox" id="everyday" name="frequency"
                    value="Almost Everyday"
                    checked={formData.frequency === 'Almost Everyday'} // Check if this option is selected in formData
                    onChange={(e) => handleInputChange('frequency', e.target.value)} />
                  <label htmlFor="everyday">Almost Everyday</label>
                </div>
                <div>
                  <input type="checkbox" id="5-6times" name="frequency"
                    value="5-6 times in a week"
                    checked={formData.frequency === '5-6 times in a week'}
                    onChange={(e) => handleInputChange('frequency', e.target.value)} />
                  <label htmlFor="5-6times">5-6 times in a week</label>
                </div>
                <div>
                  <input type="checkbox" id="3-4times" name="frequency"
                    value="3-4 times in a week"
                    checked={formData.frequency === '3-4 times in a week'}
                    onChange={(e) => handleInputChange('frequency', e.target.value)} />
                  <label htmlFor="3-4times">3-4 times in a week</label>
                </div>
                <div>
                  <input type="checkbox" id="never" name="frequency"
                    value="Never"
                    checked={formData.frequency === 'Never'}
                    onChange={(e) => handleInputChange('frequency', e.target.value)} />
                  <label htmlFor="never">Never</label>
                </div>
              </div>
            </div>
            <div className="body-btn center">
              <div className="buttons" style={{ marginTop: '10px' }}>
                <button className='button2' type="button" onClick={prevPage}>Previous</button>
                <button className='button3' type="button" onClick={nextPage}>Next</button>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (currentPage === 3) {
      return (
        <div className="body-survey">
          <div className="box">
            <div className="form" >
              <div class="container">
                <div style={{ marginLeft: '-10px' }}>
                  <h4>Do you have any dietary restrictions?</h4>
                  <p className="warning-p1">{foodHabitsWarning}</p>
                  <div>
                    <input type="checkbox" id="eat-everything" name="foodHabits"
                      value="I eat everything"
                      checked={formData.foodHabits.includes('I eat everything')}
                      onChange={(e) => handleFoodHabitsChange('I eat everything', e.target.checked)} />
                    <label htmlFor="eat-everything">I eat everything</label>
                  </div>
                  <div>
                    <input type="checkbox" id="vegetarian" name="foodHabits"
                      value="Vegetarian"
                      checked={formData.foodHabits.includes('Vegetarian')}
                      onChange={(e) => handleFoodHabitsChange('Vegetarian', e.target.checked)} />
                    <label htmlFor="vegetarian">Vegetarian</label>
                  </div>
                  <div>
                    <input type="checkbox" id="low-carb-keto" name="foodHabits"
                      value="Low carb or keto"
                      checked={formData.foodHabits.includes('Low carb or keto')}
                      onChange={(e) => handleFoodHabitsChange('Low carb or keto', e.target.checked)} />
                    <label htmlFor="low-carb-keto">Low carb or keto</label>
                  </div>
                  <div>
                    <input type="checkbox" id="sugar-free" name="foodHabits"
                      value="Sugar free"
                      checked={formData.foodHabits.includes('Sugar free')}
                      onChange={(e) => handleFoodHabitsChange('Sugar free', e.target.checked)} />
                    <label htmlFor="sugar-free">Sugar free</label>
                  </div>
                  <div>
                    <input type="checkbox" id="gluten-free" name="foodHabits"
                      value="Gluten free"
                      checked={formData.foodHabits.includes('Gluten free')}
                      onChange={(e) => handleFoodHabitsChange('Gluten free', e.target.checked)} />
                    <label htmlFor="gluten-free">Gluten free</label>
                  </div>
                  <div>
                    <input type="checkbox" id="dairy-free" name="foodHabits"
                      value="Dairy free"
                      checked={formData.foodHabits.includes('Dairy free')}
                      onChange={(e) => handleFoodHabitsChange('Dairy free', e.target.checked)} />
                    <label htmlFor="dairy-free">Dairy free</label>
                  </div>
                </div>
                <div className="body-btn center">
                  <div className="buttons" style={{ marginTop: '192px' }}>
                    <button className="button2" type="button" onClick={prevPage}>Previous</button>
                    <button className="button3" type="button" onClick={nextPage}>Next</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (currentPage === 4) {
      return (
        <div className="body-survey">
          <div className="box">
            <div className="form">
              <h4>Which areas need the most attention?</h4>
              <p className="warning-p">{bodyTargetWarning}</p>
              <div>
                <input type="checkbox" id="belly" name="bodyTarget"
                  value="Belly"
                  checked={formData.bodyTarget.includes('Belly')} // Check if this option is selected in formData
                  onChange={(e) => handleBodyTargetChange('Belly', e.target.checked)} />
                <label htmlFor="belly">Belly</label>
              </div>
              <div>
                <input type="checkbox" id="butt" name="bodyTarget"
                  value="Butt"
                  checked={formData.bodyTarget.includes('Butt')} // Check if this option is selected in formData
                  onChange={(e) => handleBodyTargetChange('Butt', e.target.checked)} />
                <label htmlFor="butt">Butt</label>
              </div>
              <div>
                <input type="checkbox" id="chest" name="bodyTarget"
                  value="Chest"
                  checked={formData.bodyTarget.includes('Chest')} // Check if this option is selected in formData
                  onChange={(e) => handleBodyTargetChange('Chest', e.target.checked)} />
                <label htmlFor="chest">Chest</label>
              </div>
              <div>
                <input type="checkbox" id="legs" name="bodyTarget"
                  value="Legs"
                  checked={formData.bodyTarget.includes('Legs')} // Check if this option is selected in formData
                  onChange={(e) => handleBodyTargetChange('Legs', e.target.checked)} />
                <label htmlFor="legs">Legs</label>
              </div>
              <div>
                <input type="checkbox" id="arms-shoulders" name="bodyTarget"
                  value="Arms & Shoulders"
                  checked={formData.bodyTarget.includes('Arms & Shoulders')} // Check if this option is selected in formData
                  onChange={(e) => handleBodyTargetChange('Arms & Shoulders', e.target.checked)} />
                <label htmlFor="arms-shoulders">Arms & Shoulders</label>
              </div>
              <div>
                <input type="checkbox" id="full-body" name="bodyTarget"
                  value="Full Body"
                  checked={formData.bodyTarget.includes('Full Body')} // Check if this option is selected in formData
                  onChange={(e) => handleBodyTargetChange('Full Body', e.target.checked)} />
                <label htmlFor="full-body">Full Body</label>
              </div>
              <div className="body-btn center">
                <div className="buttons" style={{ marginTop: '-100px' }}>
                  <button className="button2" type="button" onClick={prevPage}>Previous</button>
                  <button className="button3" type="button" onClick={nextPage}>Next</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (currentPage === 5) {
      return (
        <div className="body-survey">
          <div className="box">
            <div className="form">
              <div style={{ marginTop: '-50px' }}>
              <h4>Choose up to 3 activities you're interested in</h4>
              <p className="warning-p">{activitiesWarning}</p>
              <div>
                <input type="checkbox" id="fitness-home" name="activities"
                  value="Fitness at home"
                  checked={formData.activities.includes('Fitness at home')} // Check if this option is selected in formData
                  onChange={(e) => handleActivitiesChange('Fitness at home', e.target.checked)} />
                <label htmlFor="fitness-home">Fitness at home</label>
              </div>
              <div>
                <input type="checkbox" id="gym" name="activities"
                  value="Gym"
                  checked={formData.activities.includes('Gym')} // Check if this option is selected in formData
                  onChange={(e) => handleActivitiesChange('Gym', e.target.checked)} />
                <label htmlFor="gym">Gym</label>
              </div>
              <div>
                <input type="checkbox" id="running" name="activities"
                  value="Running"
                  checked={formData.activities.includes('Running')} // Check if this option is selected in formData
                  onChange={(e) => handleActivitiesChange('Running', e.target.checked)} />
                <label htmlFor="running">Running</label>
              </div>
              <div>
                <input type="checkbox" id="hiit" name="activities"
                  value="HIIT"
                  checked={formData.activities.includes('HIIT')} // Check if this option is selected in formData
                  onChange={(e) => handleActivitiesChange('HIIT', e.target.checked)} />
                <label htmlFor="hiit">HIIT</label>
              </div>
              <div>
                <input type="checkbox" id="yoga" name="activities"
                  value="Yoga"
                  checked={formData.activities.includes('Yoga')} // Check if this option is selected in formData
                  onChange={(e) => handleActivitiesChange('Yoga', e.target.checked)} />
                <label htmlFor="yoga">Yoga</label>
              </div>
              <div>
                <input type="checkbox" id="zumba" name="activities"
                  value="Zumba"
                  checked={formData.activities.includes('Zumba')} // Check if this option is selected in formData
                  onChange={(e) => handleActivitiesChange('Zumba', e.target.checked)} />
                <label htmlFor="zumba">Zumba</label>
              </div>
              <div>
                <input type="checkbox" id="swimming" name="activities"
                  value="Swimming"
                  checked={formData.activities.includes('Swimming')} // Check if this option is selected in formData
                  onChange={(e) => handleActivitiesChange('Swimming', e.target.checked)} />
                <label htmlFor="swimming">Swimming</label>
              </div>
              </div>
              <div className="body-btn center">
                <div className="buttons" style={{ marginTop: '-100px' }}>
                  <button className="button2" type="button" onClick={prevPage}>Previous</button>
                  <button className="button3" type="button" onClick={nextPage}>Next</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (currentPage === 6) {
      return (
        <div className="body-survey">
          <div className="box">
            <div className="form">
              <h4>Allow us to serve you better</h4>
              <div className="inputBox">
                <span>Kindly enter your Email
                  <label className="required-asterisk">*</label>
                </span>
                <input type="email" id="email" name="email" required value={formData.email}
                  onChange={(e) => handleInputChange('email', e.target.value)} />
                <i></i>
                <p className="warning">{emailValidationError}</p>
              </div>
              <div className="inputBox">
                <span>Kindly enter your contact number
                  <label className="required-asterisk">*</label>
                </span>
                <input type="tel" id="phone" name="phone" required value={formData.phone}
                  onChange={(e) => handleInputChange('phone', e.target.value)} />
                <i></i>
                <p className="warning">{phoneValidationError}</p>
              </div>
              <div className="body-btn center" >
                <div className="buttons" style={{ marginTop: '-50px' }}>
                  <button className="button2" type="button" onClick={prevPage}>Previous</button>
                  <button className="button3" type="submit" onClick={handleSubmit}>Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>

      );
    };
  };

  return (
    <div className="form-container">
      {formSubmitted ? (
        <div className="body-survey" style={{ height: '300px' }}>
          <div className="box" style={{ height: '300px' }}>
            <div className="form" style={{ height: '295px' }}>
              <h3>Thank you for submitting your details!</h3>
            </div>
          </div>
        </div>
      ) : (
        <form id="surveyForm" onSubmit={handleSubmit}>
          {renderFormPage()}
        </form>
      )}
    </div>
  );
};


export default MultiPageForm;
