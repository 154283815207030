import React, { useEffect } from "react";
// import Header from "../shared/Header";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "react-router-dom";
import Popup from "../components/Popup";

const ReadMoreContent = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
    }, []);

    return (
        <>
            {/* <Header /> */}
            <div className="bg-light">
                <div className="container p-5">
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to="/">Home</Link>
                        </BreadcrumbItem>
                    </Breadcrumb>
                    <div className="read-more-content">
                        <Popup />
                        <div style={{ textAlign: 'justify' }} >
                            {/* Video Component */}
                            <video width="100%" height="560" loop autoPlay muted playsInline>
                                <source src="/AppVideo.mp4" frameBorder="0" allowFullScreen type="video/mp4" />
                            </video>
                            <p style={{ fontSize: '25px' }}>
                                Looking for the ultimate fitness app in India? Look no further than Greendumbells!
                                <strong>Our HOME WORKOUT FITNESS AND DIET APP</strong> is your all-in-one solution, whether you're a seasoned fitness enthusiast or just starting your health journey.
                            </p>
                            <h2><b>Why Choose Greendumbells for Your Fitness Goals?</b></h2>
                            <p> </p>
                            <div style={{ fontSize: '23px' }}>
                                <ul>
                                    <li>
                                        <strong>Best Online Fitness App in India:</strong> Greendumbells stands out as the premier choice for fitness in India. Our app is meticulously tailored to cater to the unique needs of the Indian audience, ensuring you get a fitness experience like no other.
                                    </li>
                                    <li>
                                        <strong>Comprehensive Home Workouts:</strong> No need for costly gym memberships or crowded fitness centers. Greendumbells provides access to a diverse array of home workout routines that are not only effective but also incredibly convenient. Get the benefits of the gym without leaving your home.
                                    </li>
                                    <li>
                                        <strong>Personalized Diet Plans:</strong> We recognize that fitness is more than just exercise; it's also about nutrition. That's why our fitness app includes personalized diet plans that complement your workouts, helping you achieve your goals faster with proper nutrition.
                                    </li>
                                    <li>
                                        <strong>Easy Download Process:</strong>
                                        Getting started with Greendumbells is a breeze! Simply visit our website and <a href="https://play.google.com/store/apps/details?id=com.greendumbells.app"><strong>Download GreenDumbells</strong></a>, our user-friendly fitness app within seconds. It's user-friendly, ensuring that you can start your fitness journey right away.
                                    </li>
                                </ul>
                                <p >
                                    Don't wait any longer; transform your life with the best online fitness app in India. Say hello to a healthier you by downloading our Greendumbells fitness app today. Your fitness journey begins here!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ReadMoreContent;
