import React, { useState, useRef } from "react";
import { Modal } from "reactstrap";
import { Divider } from "antd";
import { Blogs } from "../components/BlogComponent";
import { useAppSelector } from "../redux/hooks";
import Testimonials from "../components/Testimonials";
import SalientFeaturesComponent from "../components/SalientFeaturesComponent";
import ServicesSlickNew from "../components/ServicesSlickNew";
import MultiPageForm from "../components/MultiPageForm";
import ReactGA from 'react-ga4'
import Banner from "../components/Banner";
import Popup from "../components/Popup";
// import AdsComponent from "../components/AdsComponent";
import { motion } from "framer-motion";
import GdBlobAnimate from "../components/GdBlobAnimate";
import PlayStoreButton from "../shared/PlayStoreButton";
import { Helmet } from 'react-helmet';
import AppContent from "../components/AppContent";
// import ImagePopup from "../components/ProductPopup";



function HomeComponent() {
  const darkMode = useAppSelector((state) => state.darkMode.isDarkMode);
  const [modalOpen, setModalOpen] = useState(false);
  const customStyle = {
    fontFamily: 'Helveticish'
  };
  const customStyle1 = {
    fontFamily: 'Libre Baskerville'
  }
  ReactGA.send({ hitType: "pageview", page: "/" });


  const buttonVariants = {
    floating: {
      scale: [1, 1.05, 1], // Scale
      opacity: [1, 0.8, 1], // Opacity
      transition: {
        scale: {
          repeat: Infinity,
          duration: 1,
        },
        opacity: {
          repeat: Infinity,
          duration: 1.5,
        },
      },
    },
  };

  var animateButton = function (e) {

    e.preventDefault()
    //reset animation
    e.target.classList.remove('animate');

    e.target.classList.add('animate');
    setTimeout(function () {
      e.target.classList.remove('animate');
    }, 700);
  };

  var bubblyButtons = document.getElementsByClassName("bubbly-button");

  for (var i = 0; i < bubblyButtons.length; i++) {
    bubblyButtons[i].addEventListener('click', animateButton, false);
  }

  return (
    <>
      {/* <ImagePopup /> */}
      <div
        className={
          darkMode
            ? "jumbotron-dark self-center text-center pt-5"
            : "self-center jumbotron-light text-center pt-5"
        }
      >

        <Helmet>
          <title>Best Home Workout Fitness and Diet App India | Greendumbells</title>
          <meta name="description" content="Get fit and stay healthy with GreenDumbells, the best home workout fitness and diet app. Achieve your fitness goals with personalized workouts and nutrition plans. " />
          <link rel="canonical" href="https://greendumbells.com/" />
        </Helmet>
        <div className="col-12 col-md-6" style={{ height: '640px' }} >
          <div className="desktop-padding">
            <h2 style={customStyle} className={darkMode ? "gradient-text" : ""}>Best Online fitness App in India</h2>
            <h4 style={customStyle1} className="font-weight-bold mt-1">
              Experience the quality of life !!
            </h4>
            {/* <h1 className={darkMode ? "gradient-text" : ""}>Welcome To GreenDumbells</h1> */}
            <h6 className="d-none d-sm-block p-3 text-muted lead">
              A tech-enabled service-based fitness community that supports natural
              ways to achieve body goals externally as well as internally. Get
              personalized exercise and meal plans to suit your body type, mental
              well-being, and physical capacity!
            </h6>
            <div className="d-md-none col-12 p-1">
              <GdBlobAnimate />
            </div>
            {/* <div className="font-weight-bold py-2 px-4 my-2 mx-2 rounded text-uppercase">
          <MultiPageForm />
          </div> */}
            <motion.button
              className="anav bubbly-button py-2 px-4 my-2 mx-2 rounded text-uppercase font-weight-bold"
              onClick={() => setModalOpen(!modalOpen)}
              variants={buttonVariants}
              initial="floating"
              animate="floating"
            >

              Get Your Customised Exercise plan For Free !!

            </motion.button>
            <h4 className="fw-bold p-3">
              {" "}
              Get Our{" "}
              <p className="text-success fw-bold text-uppercase d-inline">
                feature packed{" "}
              </p>{" "}
              App
            </h4>
            {/* <img className="col-6 col-md-3 img-fluid" src="/app-store-icon.png" alt="Alt text for image" /> */}
            <div className=" d-block" >
              <div className="desktop-padding">
                <PlayStoreButton />
              </div>
            </div>
          </div>
        </div>
        <div className={darkMode ? "darkMode mt-4" : "bg-white mt-4"}>
          <Banner />
        </div>
        {/* <div className={darkMode ? "darkMode" : "bg-white"}>
        <AdsComponent/>
      </div> */}
        <div className={darkMode ? "darkMode pt-5" : "bg-white pt-5"}>
          {/* <h2 className="text-center text-uppercase fw-semibold">
          What Do We Offer For Your Good Health?
        </h2> */}
          <div style={{ marginLeft: "20px" }}>
            <div className="row col text-center ">
              <div className="col-12 p-3">
                <ServicesSlickNew darkMode={darkMode} />
              </div>
            </div>
            <Divider />
            <SalientFeaturesComponent darkMode={darkMode} />
            <Divider />
            <div>
              {/* <h2 className="text-center text-uppercase my-6">testimonials</h2> */}
              <div>
                <Testimonials />
              </div>
            </div>
            <Divider />
            {/* <div className="video-container">              
              <video className="responsive-video" width="100%" height="400" loop autoPlay muted playsInline>
                <source src="/Website mustard oil homepage.mp4" frameBorder="0" allowFullScreen type="video/mp4" />
              </video>
            </div> */}
        
            <div>
              <div>
                <AppContent />
              </div>
            </div>
            <Divider />

            {/* <div>
            <h2 className="text-center text-uppercase">Food Product</h2>
            <div>
              <FoodComponent />
            </div>
          </div> */}
            {/* <Divider /> */}

            {/* <a href="https://play.google.com/store/apps/details?id=com.greendumbells.app" style={{ "textDecoration": "none" }}>
              <div className="text-start align-items-center align-self-center" style={{ "backgroundImage": "url(joinCommunityBg.png)", "backgroundPosition": "center", "backgroundSize": "cover", "height": "50vh", "textDecoration": "none" }}>
                <h2 className="text-3xl text-uppercase font-semibold pt-2 pb-5 ml-4">
                  Join our community
                </h2>
              </div>
            </a> */}
            <a href="https://wa.me/917008809907" target="_blank" rel="noopener noreferrer">
              <div className="video-container">
                {/* Video Section */}
                <video className="responsive-video" width="100%" height="600" loop autoPlay muted playsInline>
                  <source src="/connect section (FOR HOMEPAGE)_2.mp4" frameBorder="0" allowFullScreen type="video/mp4" />
                </video>              
                
              </div>
            </a>

            <Divider />
            <div>
              {/* <h2 className="text-center text-3xl text-uppercase font-semibold my-6">
            Read our expert blog
          </h2> */}
              <div>
                <Blogs />
              </div>
            </div>
          </div>
        </div>
        <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
          <MultiPageForm modalOpen={modalOpen} setModalOpen={setModalOpen} />
        </Modal>
      </div>
    </>
  );
}

export default HomeComponent;
