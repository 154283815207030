 import React, { useEffect, useState } from 'react'
import '../App.css'
import { Dashurl } from '../components/Baseurl'
import {motion} from 'framer-motion'

function TextLine() {
    const [line, setLine] = useState([])
    useEffect(() => {
        const fetchLine = async () => {
            try {
                const response = await fetch(`${Dashurl}line`);
                if (response.ok) {
                    const data = await response.json();
                    setLine(data);
                } else {
                    console.error("Error:", response.status);
                }
            } catch (err) {
                console.error("Error:", err);
            }
        }

        fetchLine()
    }, [])

    
    return (
        <motion.div 
        className="text-line-container"        
        >
            {line.length > 0 && (
                <marquee className="text-line-marquee" >{line[0].content}</marquee>
            )}
        </motion.div>
    )
}

export default TextLine