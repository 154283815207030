/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";

import { Dashurl, RazorpayApiKey } from "../components/Baseurl";
import useHttp from "../hooks/useHttp";
import { AuthenticationContext } from "./authentication.context";
import { showNotification } from "../shared/Notification";
import Swal from "sweetalert2";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export const CartContext = createContext({
  onGetCart: (callback, loader, notify) => null,
  onAddCart: (data, callback, errorCallBack, loader, notify) => null,
  onChangeQuantity: (id, qty, callback, errorCallBack, loader, notify) => null,
  onRemoveCart: (id, callback, errorCallBack, loader, notify) => null,
  onCheckout: (data, callback, errorCallBack, loader, notify) => null,
  onVerifyPayment: (data, callback, errorCallBack, loader, notify) => null,
  onGetCoupons: (callback, loader, notify) => null,
  onAddAddress: (address, callback, errorCallBack, loader, notify) => null,
  onRemoveAddress: (id, callback, errorCallBack, loader, notify) => null,
  coupons: [],
  cartItems: [],
  // lastAddress: {},
});

export const CartContextProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  // const [lastAddress, setLastAddress] = useState({});
  const { userData, onsetVerifiedUser } = useContext(AuthenticationContext);
  const [coupons, setCoupons] = useState([]);
  const { sendRequest } = useHttp();
  const [totalQuantity, setTotalQuantity] = useState(0);


  useEffect(() => {
    if (userData) {
      onGetCart((result) => {
        setCartItems(result.cart);
        // setLastAddress(result.address);
        onGetCoupons();
      });
    }
  }, [userData]);

  const onGetCart = async (
    callback = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: Dashurl + "cart",
      },
      {
        successCallback: callback,
      },
      loader,
      notify
    );
  };

  const onAddCart = async (
    data,
    callback,
    errorCallBack = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: Dashurl + `cart`,
        type: "POST",
        data: data,
      },
      {
        successCallback: async (result) => {
          callback(result);
          onGetCart((response) => setCartItems(response.cart));
        },
        errorCallback: errorCallBack,
      },
      loader,
      notify
    );
  };

  const onRemoveCart = async (
    id,
    callback,
    errorCallBack = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: Dashurl + `cart/${id}`,
        type: "DELETE",
      },
      {
        successCallback: async (result) => {
          callback(result);
          onGetCart((response) => setCartItems(response.cart));
        },
        errorCallback: errorCallBack,
      },
      loader,
      notify
    );
  };
  const onChangeQuantity = async (
    productId, quantityChange,
    callback,
    errorCallBack = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: `${Dashurl}cart/${productId}`,
        type: "POST",
        data: { quantity: quantityChange },
      },
      {
        successCallback: async (result) => {
          // callback(result);
          onGetCart((result) => setCartItems(result.cart));
        },
        errorCallback: errorCallBack,
      },
      loader,
      notify
    );
  };


  const onGetCoupons = (callback = () => null, loader = true, notify = true) => {
    sendRequest(
      {
        url: Dashurl + "coupons",
      },
      {
        successCallback: (result) => {
          setCoupons(result);
          callback(result);
        },
      },
      loader,
      notify
    );
  };

  const onPayment = async (
    data,
    successCallback = () => { },
    errorCallBack = () => { }
  ) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      Swal.fire(
        "SDK failure",
        "Razorpay SDK failed to load. Are you online?",
        "error"
      );
      return;
    }

    // Getting the order details back
    const { amount, id: order_id, currency, prices, address, items } = data;
    const options = {
      key: RazorpayApiKey, // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: "Green Dumbells.",
      description: "Order Payment",
      image: "https://greendumbells.com/GdLogoWhite.png",
      order_id: order_id,
      handler: async function (response) {
        const data = {
          response: response,
          prices: prices,
          address: address,
          items: items,
        };

        onVerifyPayment(data, successCallback, errorCallBack);
      },
      modal: {
        escape: false,
        onDismiss: () => {
          errorCallBack();
        },
      },
      prefill: {
        name: address.name,
        contact: address.phone,
      },
      notes: {},
      theme: {
        color: "#77e503",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    paymentObject.on("payment.failed", function (response) {
      paymentObject.close();
      const elementToRemove = document.querySelector(".razorpay-container");
      if (elementToRemove) {
        elementToRemove.remove();
      }
      document.body.style.overflow = "visible";
      document.body.style.overflowX = "hidden";

      let message = response?.error?.description;
      Swal.fire("Payment failed", message, "error");
      sendEmail({ email: userData.email, msg: "error" });
      errorCallBack();
    });
  };

  const onCheckout = async (
    data,
    callback,
    errorCallBack = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: Dashurl + `order`,
        type: "POST",
        data: data,
      },
      {
        successCallback: async (result) => {
          onPayment(
            {
              ...data,
              ...result,
            },
            callback,
            errorCallBack
          );
        },
        errorCallback: errorCallBack,
      },
      loader,
      notify
    );
  };

  const onAddAddress = async (
    address,
    callback,
    errorCallBack = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: Dashurl + 'address',
        type: 'POST',
        data: address,
      },
      {
        successCallback: async (result) => {
          // setLastAddress(result.address);
          callback(result);
        },
        errorCallback: errorCallBack,
      },
      loader,
      notify
    );
  };

  const onRemoveAddress = async (
    id,
    callback,
    errorCallBack = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: Dashurl + `address/${id}`,
        type: "DELETE",
      },
      {
        successCallback: async (result) => {
          callback(result);
        },
        errorCallback: errorCallBack,
      },
      loader,
      notify
    );
  };

  const sendEmail = async (data) => {
    sendRequest(
      {
        url: Dashurl + `send-email`,
        type: "POST",
        data: data
      },
      {
        successCallback: async (result) => {
          Swal.fire(
            "Order Confirmation email sent"
          );
        },
      },
    );
  };

  const onVerifyPayment = async (
    data,
    callback,
    errorCallBack = () => { },
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: Dashurl + `order/verify`,
        type: "POST",
        data: data,
      },
      {
        successCallback: async (result) => {
          callback(result);
          Swal.fire(
            "Payment Successfull",
            "Order Placed Successfully",
            "success"
          );
          onsetVerifiedUser();
          sendEmail({ email: userData.email, name: userData.name, data: data, msg: "order_confirmation" });
          onGetCart((response) => setCartItems(response.cart));
        },
        errorCallback: errorCallBack,
      },
      loader,
      notify
    );
  };

  return (
    <CartContext.Provider
      value={{
        onGetCart,
        onAddCart,
        onRemoveCart,
        onCheckout,
        onVerifyPayment,
        onGetCoupons,
        coupons,
        cartItems,
        // lastAddress,
        onAddAddress,
        onChangeQuantity,
        onRemoveAddress
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
