import styled from 'styled-components';

const Container = styled.div`
  text-align: center;
`;

const Image = styled.img`
  width: 80%; /* Set the width to 80% for both mobile and desktop views */
  max-width: 500px; /* Limit the maximum width of the image to 400px */
  height: auto;
  display: block; /* Center the image */
  margin: 0 auto 20px; /* Add margin at the bottom to create space between image and heading */
`;

const Heading = styled.h2`
  margin-top: 20px; /* Add margin at the top of the heading to move it upward */
`;

const NotFound = () => {
  return (
    <Container>
      <Heading>404 - Not Found</Heading>
      <Image src="error.jpg" alt="404 error" />
      <h3>The page you are looking for does not exist.</h3>
    </Container>
  );
};

export default NotFound;
