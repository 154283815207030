import React from "react";
import { Helmet } from 'react-helmet'
function Return({ darkMode }: any) {
  return (
    <div className={darkMode ? "text-light bg-dark" : "text-dark bg-light"}>
      <Helmet>
        <title>Return Policy | Greendumbells</title>
        <meta name="description" content="Get fit and stay healthy with greendumbells, the best home workout fitness and diet app. Achieve your fitness goals with personalized workouts and nutrition plans." />
      </Helmet>
      <article className="container py-3">
        <header className="entry-header ast-no-thumbnail ast-no-meta">
          <h1 className="entry-title" itemProp="headline">
            RETURN / CANCELLATION POLICY
          </h1>{" "}
        </header>

        <div>
          <p>Last updated 15 march 2023.</p>

          <p></p>

          <p>
            <strong>Refunds</strong>
          </p>

          <p>All Sales are final and no refund will be issued .</p>

          <p>We do not provide any refund to any customer after transaction </p>

          <p></p>

          <p></p>

          <p>
            <strong>Questions</strong>
          </p>

          <p>
            If you have any questions/doubts regarding our return/cancellation
            policy , Feel free to react out to us at{" "}
          </p>

          <p>+91-7008809907</p>

          <p>info@greendumbells.com</p>
        </div>
      </article>
    </div>
  );
}

export default Return;
