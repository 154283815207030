import React, { useContext, useEffect, useState } from "react";
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavbarToggler,
  Collapse,
  NavItem,
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";
import ThemeToggle from "../components/ThemeToggle";
import "../components/style.css";
import axios from "axios";
import { Dashurl } from "../components/Baseurl";
import { MenuButton, MenuItem, SubMenu, Menu } from "@szhsin/react-menu";
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";
import { NavDropdown } from "react-bootstrap";
import { AuthenticationContext } from "../services/authentication.context";
function NavBar(props) {
  const [open, setOpen] = useState(false);
  const { userData, onLogout } = useContext(AuthenticationContext);
  const darkMode = useAppSelector((state) => state.darkMode.isDarkMode);
  const navigate = useNavigate();
  const toggleMenu = () => {
    setOpen(!open);
  };

  const closeNavbar = () => {
    setOpen(false);
  };

  const onClickLogout = () => {
    onLogout(() => {
      navigate("/");
    });
  };

  const openPlayStoreLink = () => {
    const playStoreURL = 'https://play.google.com/store/apps/details?id=com.greendumbells.app';
    window.open(playStoreURL, '_blank');
  };

  return (
    <Navbar
      className="fw-bold"
      light={!darkMode}
      dark={darkMode}
      expand="md"
      style={{ zIndex: 1000 }}
    >
      <div className="container text-end">
        <NavbarBrand className="ml-2" href="/">
          <img
            src="/gd-logo.jpg"
            height="40"
            width="46"
            alt="GreenDumbells logo"
          />
        </NavbarBrand>
        <div className="d-flex ml-auto pr-3 d-md-none">
          {/* <img height="40" width="40" src="/appstorenav.png" alt="Alt text for image" /> */}
          {/* <ThemeToggle /> */}
          {/* <input
              style={{ height: "30px", width: "50px" }}
              className="form-check-input"
              type="checkbox"
              id="lightSwitch"
              onClick={handleDarkModeToggle}
            /> */}
          <a href="https://instagram.com/green_dumbells?igshid=MzRlODBiNWFlZA==">
            <img
              className="mr-2"
              height="40"
              width="40"
              src="/instagram.png"
              alt="Instagram"
            />
          </a>
          <a role="button" onClick={openPlayStoreLink}>
            <img
              className="d-inline align-self-center"
              height="40"
              width="40"
              src="/gplaynav.png"
              alt="playstore "
            />
          </a>
        </div>
        <div className="body-menu">
          <NavbarToggler
            className={`menu ${open ? "opened" : ""}`}
            onClick={() => toggleMenu()}
            aria-label="Main Menu"
          >
            <svg width="40" height="40" viewBox="0 0 100 100">
              <path
                className="line line1"
                d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
              />
              <path className="line line2" d="M 20,50 H 80" />
              <path
                className="line line3"
                d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
              />
            </svg>
          </NavbarToggler>
        </div>
        <Collapse isOpen={open} navbar>
          <Nav navbar className="mr-auto ml-3 text-start">
            <NavItem>
              <NavLink className="nav-link" to="/" onClick={closeNavbar}>
                <span className="fa fa-home fa-lg"></span> Home
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className="nav-link"
                to="/about-us"
                onClick={closeNavbar}
              >
                <span className="fa fa-info fa-lg"></span> About Us
              </NavLink>
            </NavItem>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <span className="fa fa-handshake-o fa-lg mr-1"></span>
                Services
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem tag={Link} to="/services/fatloss">
                  <strong>Fatloss</strong>
                </DropdownItem>
                <DropdownItem tag={Link} to="/services/nutrition">
                  <strong>Nutrition</strong>
                </DropdownItem>
                <DropdownItem tag={Link} to="/services/mind-body-balance">
                  <strong>Mind-Body Balance</strong>
                </DropdownItem>
                <DropdownItem tag={Link} to="/services/lifestyle-guidance">
                  <strong>Lifestyle Guidance</strong>
                </DropdownItem>
                <DropdownItem tag={Link} to="/services/muscle-gain">
                  <strong>Muscle Gain</strong>
                </DropdownItem>
                {/* Add more dropdown items as needed */}
              </DropdownMenu>
            </UncontrolledDropdown>
            {/* <NavItem>
              <NavLink className="nav-link" to="/tools">
                <span className="fa fa-heartbeat fa-lg"></span>
                {" "}
                Tools
              </NavLink>
            </NavItem> */}
            {/* <NavItem>
                <NavLink className="nav-link" to="/store"> Store
                </NavLink>
              </NavItem> */}
            <NavItem>
              <NavLink
                className="nav-link"
                to="/articles"
                onClick={closeNavbar}
              >
                <span className="fa fa-file-archive-o fa-lg"></span> Articles
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="nav-link" to="/product" onClick={closeNavbar}>
                <span className="fa fa-shopping-cart fa-lg"></span> Food Product
              </NavLink>
            </NavItem>

            {/* prodcuts */}
            {/* <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <span className="fa fa-shopping-cart fa-lg mr-1"></span>
                Food Products
              </DropdownToggle>
            </UncontrolledDropdown> */}

            <NavItem>
              <NavLink className="nav-link" to="/faqs" onClick={closeNavbar}>
                <span className="fa fa-question fa-lg"></span> FAQs
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className="nav-link"
                to="/contact-us"
                onClick={closeNavbar}
              >
                <span className="fa fa-address-book fa-lg"></span> Contact Us
              </NavLink>
            </NavItem>
            {!userData && (
              <NavItem>
                <NavLink className="nav-link" to="/login" onClick={closeNavbar}>
                  <span className="fa fa-sign-in fa-lg"></span> Login
                </NavLink>
              </NavItem>
            )}
            {userData && (
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  <span className="fa fa-user fa-lg mr-1"></span>
                  {userData.name.split(' ')[0]}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem tag={Link} to="/cart">
                    <strong>My Cart</strong>
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/orders">
                    <strong>My Orders</strong>
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/address">
                    <strong>Saved Addresses</strong>
                  </DropdownItem>
                  <DropdownItem tag={Link} onClick={onClickLogout}>
                    <strong>Logout</strong>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            )}
          </Nav>
          <Nav navbar>
            <NavItem className="m-1">
              <div
                className="d-none d-md-block pr-2 ml-auto"
                style={{ marginTop: "-1em" }}
              >
                <a href="https://instagram.com/green_dumbells?igshid=MzRlODBiNWFlZA==">
                  <img
                    className="mt-3 mr-2"
                    height="40"
                    width="40"
                    src="/instagram.png"
                    alt="Instagram"
                  />
                </a>
                {/* <ThemeToggle /> */}
                {/* <input
                  style={{ height: "30px", width: "50px" }}
                  className="form-check-input"
                  type="checkbox"
                  id="lightSwitch"
                  onClick={handleDarkModeToggle}
                /> */}
              </div>
            </NavItem>
            {/* <NavItem className="m-1">
                {!props.auth.isAuthenticated ? (
                  <Button outline>
                    <span className="fa fa-sign-in fa-lg"></span> Login
                    {props.auth.isFetching ? (
                      <span className="fa fa-spinner fa-pulse fa-fw"></span>
                    ) : null}
                  </Button>
                ) : (
                  <div>
                    <div className="navbar-text mr-3">
                      {props.auth.user.username}
                    </div>
                    <Button outline>
                      <span className="fa fa-sign-out fa-lg"></span> Logout
                      {props.auth.isFetching ? (
                        <span className="fa fa-spinner fa-pulse fa-fw"></span>
                      ) : null}
                    </Button>
                  </div>
                )}
              </NavItem> */}
          </Nav>
        </Collapse>
        <div className="d-none d-md-block ml-auto">
          {/* <img height="40" width="40" src="/appstorenav.png" alt="Alt text for image" /> */}
          <a role="button" onClick={openPlayStoreLink}>
            <img
              height="40"
              width="40"
              src="/gplaynav.png"
              alt="google play icon"
            />
          </a>
        </div>
      </div>
    </Navbar>
  );
}

export default NavBar;
