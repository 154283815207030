import { Carousel } from "react-responsive-carousel";
import classes from "../components/ProductDetails.module.css";
import cartClasses from "../components/Cart.module.css";
import ReactPlayer from "react-player";
import { useContext, useEffect, useRef, useState } from "react";
import { getCurrencyLocaleString } from "../utility/helper";
import {
  Button,
  Spinner,
} from "react-bootstrap";
import { RiFlashlightFill, RiShoppingCartFill } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Dashurl } from "../components/Baseurl";
import Swal from "sweetalert2";
import { CartContext } from "../services/cart.context";
import "../components/ProductDetails.css";
import ReviewComponent from "../components/ReviewComponent";
import { Rate } from "antd";

const renderCustomThumbs = (images, videos) => {
  const thumbList = images.map((image, index) => (
    <picture key={index}>
      <img
        key={index}
        src={`${image}`}
        alt={"pro" + index}
        height={100}
        style={{
          objectFit: "contain",
        }}
      />
    </picture>
  ));
  const videosList = videos.map((video, index) => <video src={video} />);
  return [...thumbList, ...videosList];
};

const VideoSlide = ({ url, isSelected }) => {
  const playerRef = useRef();

  useEffect(() => {
    playerRef.current?.seekTo(0); // reset video to start
  }, [isSelected]);

  return (
    <ReactPlayer
      ref={playerRef}
      playing={isSelected}
      loop
      muted
      stopOnUnmount
      width={"100%"}
      height={"100%"}
      className={classes.video}
      controls
      url={url}
    />
  );
};

const ProductDetailsComponent = () => {
  const [product, setProduct] = useState(null);
  const [similarProducts, setSimilarProducts] = useState(null);
  const { onAddCart, cartItems } = useContext(CartContext);
  const [cartLoading, setCartLoading] = useState(false);
  const [alreadyInCart, setAlreadyInCart] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [overallRating, setOverallRating] = useState(0);
  
  const handleIncrement = () => {
    if (quantity < product.quantity) {
      setQuantity(prevQuantity => prevQuantity + 1);
    }
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(prevQuantity => prevQuantity - 1);
    }
  };

  const navigate = useNavigate();
  let { productId } = useParams();

  useEffect(() => {
    if (productId) {
      onGetProduct();
      onGetAverageRating();
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [productId]);

  useEffect(() => {
    if (cartItems && productId) {
      let cartExists = cartItems.find((c) => c._id === productId);
      setAlreadyInCart(cartExists);
    }
  }, [cartItems, productId]);

  const onGetProduct = () => {
    axios
      .get(`${Dashurl}product/${productId}`)
      .then((response) => {
        if (!response.data || !response.data.product) {
          navigate("/products");
          return;
        }
        setProduct(response.data.product);
        setSimilarProducts(response.data.similarProducts);
        // setBannerData(response.data);
      })
      .catch((error) => {
        let message =
          error?.response?.data?.error || "Error in fetching products data";
        Swal.fire("Error Occured", message, "error");
        console.error("Error fetching products data:", error);
      });
  };
  const onGetAverageRating = () => {
    axios
      .get(`${Dashurl}average-rating/${productId}`)
      .then((response) => {
        setOverallRating(response.data.averageRating)
      })
      .catch((error) => {
        let message =
          error?.response?.data?.error || "Error in fetching average rating data";
        Swal.fire("Error Occured", message, "error");
        console.error("Error fetching products data:", error);
      });
  };

  const onClickAddToCart = () => {
    let data = {
      id: product._id,
      quantity: quantity
    };
    if (cartLoading) {
      return;
    }
    setCartLoading(true);
    onAddCart(
      data,
      () => {
        setCartLoading(false);
      },
      () => {
        setCartLoading(false);
      }
    );
  };

  const onClickBuyNow = () => {
    let data = {
      id: product._id,
      quantity: quantity,
    };
    if (cartLoading) {
      return;
    }
    setCartLoading(true);
    onAddCart(
      data,
      () => {
        setCartLoading(false);
        navigate("/cart");
      },
      () => {
        setCartLoading(false);
      }
    );
  };

  return (
    <div className={classes.container}>
      {product && (
        <>
          <div className="row">
            <div className="col-md-6 pr-5">
              <Carousel
                className="mt-5"
                // autoPlay
                dynamicHeight
                emulateTouch
                infiniteLoop
                renderThumbs={() =>
                  renderCustomThumbs(product.images, product.videos)
                }
                stopOnHover
                showStatus={false}
              >
                {product.images.map((el, i) => {
                  return <img src={el} key={i} alt={`slide-${i}`} />;
                })}
                {product.videos.map((el, i) => {
                  return <VideoSlide key={i} url={el} />;
                })}
              </Carousel>
            </div>
            <div className="col-md-6 mt-5">
              <div>
                <h1 className={classes.title}>{product.name}</h1>
                <h2 className={classes.category}>{product.category.name}</h2>

                <h4 className="mt-4" style={{ display: 'flex', alignItems: 'center' }}>
                  <span>
                    <Rate allowHalf disabled defaultValue={overallRating ? overallRating : 0} />
                  </span>
                  <span className={classes.category} style={{ marginLeft: '10px', marginTop: "8px" }}>
                    ({overallRating.toFixed(2)})
                  </span>
                </h4>
                <div className={classes.prices}>
                  <span className={classes.finalPrice}>
                    {getCurrencyLocaleString(product.finalPrice)}
                  </span>
                  {product.discount ? (
                    <span className={classes.price}>
                      {getCurrencyLocaleString(product.price)}
                    </span>
                  ) : null}
                  {product.discount ? (
                    <span className={classes.discount}>
                      {product.discount}% off
                    </span>
                  ) : null}

                  {product.quantity < 10 && (
                    <p
                      className="text-danger mt-2"
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      {product.quantity === 0 ? "Out of Stock" : `Only ${product.quantity} left in stock`}
                    </p>
                  )}
                </div>

                {/* <div
                  className={classes.description}
                  dangerouslySetInnerHTML={{
                    __html: product.description,
                  }}
                /> */}
                <img
                  src="/quality.png"
                  alt="Mustard Oil Bottle"
                  style={{ width: '100%', maxWidth: '1100px', height: 'auto' }}
                />
                {/* {loading && (
                <Button>
                  <Spinner size="sm" className="mr-3" />
                  PLEASE WAIT...
                </Button>
              )}
              {!loading && (
                <Button type="submit">
                  {" "}
                  {otpResponse ? "VERIFY OTP" : "SEND OTP"}{" "}
                </Button>
              )} */}
                <div className={classes.buttons}>
                  <div className={cartClasses.unit}>

                    <div className={cartClasses.minus} onClick={handleDecrement}>
                      <i aria-hidden="true" className="fa fa-minus"></i>
                    </div>
                    <div className={cartClasses.unitQuantity}>{quantity}</div>

                    <div className={cartClasses.plus} onClick={handleIncrement}>
                      <i aria-hidden="true" className="fa fa-plus"></i>
                    </div>

                  </div>
                  {alreadyInCart && (
                    <Button
                      className={`${classes.cartBtn} ${classes.btn}`}
                      onClick={() => navigate("/cart")}
                    >
                      <RiShoppingCartFill color="#fff" size={20} />
                      GO TO CART
                    </Button>
                  )}
                  {!alreadyInCart && (
                    <Button
                      className={`${classes.cartBtn} ${classes.btn}`}
                      onClick={onClickAddToCart}
                    >
                      {cartLoading ? (
                        <Spinner size="sm" className="mr-3" />
                      ) : (
                        <RiShoppingCartFill color="#fff" size={20} />
                      )}

                      {cartLoading ? "ADDING TO CART" : "ADD TO CART"}
                    </Button>
                  )}

                  <Button
                    className={`${classes.buyBtn} ${classes.btn}`}
                    onClick={onClickBuyNow}
                  >
                    <RiFlashlightFill color="#fff" size={20} />
                    BUY NOW
                  </Button>
                </div>
              </div>
            </div>
          </div>

          {/* <div className={classes.similarProducts}>
            <p className={classes.title}>SIMILAR PRODUCTS</p>
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={50}
              slidesPerView={1}
              breakpoints={{
                968: {
                  slidesPerView: 3,
                },
              }}
              navigation
              pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
            >
              {similarProducts.map((product, i) => {
                let { _id } = product;
                return (
                  <SwiperSlide key={_id}>
                    <ProductCard product={product} index={i} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div> */}

          <div className="main-container">
            <div className="description-container1">
              <h1><b>Description</b></h1>
              <p>Elevate your culinary experiences with GreenDumbells Kachi Ghani Cold Pressed Mustard Oil. Our mustard oil, extracted using the traditional Kachi Ghani method, is celebrated as India's best mustard oil.</p>
              <h2>Key Features:</h2>
              <p> </p>
              <ul>
                <li><b>Pure & Natural:</b> GreenDumbells delivers 100% pure and natural grade 1 mustard oil, sourced from the finest sarso seeds, ensuring authenticity in every drop.</li>
                <li><b>Cholesterol-Free:</b> Our mustard oil is entirely cholesterol-free, making it the ideal choice for a heart-healthy lifestyle.</li>
                <li><b>Argemone Oil-Free:</b> Trust GreenDumbells for mustard oil that is entirely free from argemone oil, guaranteeing your safety and well-being.</li>
                <li><b>No Additive Flavors:</b> Our pure mustard oil preserves the genuine, robust taste without artificial flavors, adding authenticity to your dishes.</li>
                <li><b>Preservative-Free:</b> GreenDumbells Mustard Oil is crafted without any preservatives, ensuring you receive the purest form of this elixir.</li>
              </ul>
            </div>
            <div className="image-container12">
              <img src="/mustard.png" alt="Mustard Oil Bottle" />
            </div>
          </div>

          <div className="main-container">
            <div className="description-container1">
              <h3>Versatile Usage</h3>
              <p>Experience the multifaceted benefits of GreenDumbells Kachi Ghani Mustard Oil. Whether it's for crafting mouthwatering dishes, nourishing your hair, or rejuvenating your skin, our mustard oil enhances every facet of your life.</p>
              <img
                src="/usage.png"
                alt="Mustard Oil Bottle"
                style={{ width: '100%', maxWidth: '1100px', height: 'auto' }}
              />
              <h3>Why Choose Us?</h3>
              <p> </p>
              <p>Elevate your culinary creations, health, and beauty regimen with GreenDumbells Kachi Ghani Cold Pressed Mustard Oil. We proudly serve the best sarso tel in India, bringing the essence of tradition and purity to your doorstep.</p>
              <p>For the best mustard oil in India, trust GreenDumbells. We ensure swift delivery to your location, so you can experience the exceptional quality of the best sarso oil available.</p>
              <p>Experience the authenticity of India's best mustard oil with GreenDumbells Elevate your culinary and wellness journey today.</p>
            </div>
            <div className="image-container12">
              <img src="/hairpic.png" alt="Mustard Oil Bottle" />
            </div>
          </div>

          <div className="Five-img" style={{ display: 'flex' }}>
            <img src="/mustard.png" alt="mustard oil 1" style={{ width: '20%', marginRight: '10px' }} />
            <img src="/mustard.png" alt="mustard oil 2" style={{ width: '20%', marginRight: '10px' }} />
            <img src="/mustard.png" alt="mustard oil 3" style={{ width: '20%', marginRight: '10px' }} />
            <img src="/mustard.png" alt="mustard oil 4" style={{ width: '20%', marginRight: '10px' }} />
            <img src="/mustard.png" alt="mustard oil 5" style={{ width: '20%' }} />
          </div>

          <ReviewComponent productId={productId} />
        </>
      )}
    </div>
  );
};

export default ProductDetailsComponent;
