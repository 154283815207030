/* eslint-disable no-throw-literal */
import { Button, Form, Spinner } from "react-bootstrap";
import classes from "../components/Login.module.css";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Dashurl, Fast2SmsApiKey, Fast2SmsUrl } from "../components/Baseurl";
import { AuthenticationContext } from "../services/authentication.context";
import { useNavigate } from "react-router-dom";
import { showNotification } from "../shared/Notification";

function generate6DigitOTP() {
  const min = 100000; // Minimum 6-digit OTP
  const max = 999999; // Maximum 6-digit OTP
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const Login = () => {


  const googleAuth = () => {
    window.open(
      `${Dashurl}auth/callback/google`,
      "_self"
    );
  };

  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);

  const [otpResponse, setOtpResponse] = useState("");

  const [seconds, setSeconds] = useState(0);

  const navigate = useNavigate();

  const { onSignin, userData } = useContext(AuthenticationContext);

  useEffect(() => {
    if (userData) {
      navigate("/");
    }
  }, [userData]);

  useEffect(() => {
    let intervalId;
    if (seconds > 0) {
      intervalId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [seconds]);

  const onResendOtp = () => {
    if (seconds === 0) {
      setOtp("");
      setSeconds(60);
      onSendOtp();
    }
  };

  const onSendOtp = async () => {
    try {
      let randomOtp = generate6DigitOTP();
      setLoading(true);
      let response = await axios.get(
        `${Fast2SmsUrl}?authorization=${Fast2SmsApiKey}&variables_values=${randomOtp}&route=otp&numbers=${phone}`
      );

      if (response.data) {
        setOtpResponse(randomOtp);
        setSeconds(60);
      }
      setLoading(false);
    } catch (e) {
      let message = e.response?.data?.message || e.toString();
      setLoading(false);
      showNotification({
        message: message,
        status: "error",
      });
    }
  };

  const onClearAll = () => {
    setOtp("");
    setSeconds(0);
    setPhone("");
    setOtpResponse("");
    setLoading(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!otpResponse) {
        let phoneRegex = /^\d{10}$/;
        if (!phone) {
          throw "Mobile number required";
        }
        if (!name) {
          throw "Name required";
        }
        if (!email) {
          throw "Email required";
        }
        if (!phoneRegex.test(phone)) {
          throw "Invalid Mobile number entered";
        }
        onSendOtp();
      } else {
        let otpRegex = /^\d{6}$/;
        if (!otp) {
          throw "OTP required";
        }
        if (!otpRegex.test(otp)) {
          throw "Invalid OTP";
        }
        if (otp != otpResponse) {
          throw "Wrong OTP Entered";
        }
        let data = {
          phone: Number(phone),
          name: name,
          email: email,
        };
        console.log(data)
        onSignin(data, () => {
          onClearAll();
          navigate("/");
        });
      }
    } catch (e) {
      let message = e.response?.data?.message || e.toString();
      setLoading(false);
      showNotification({
        message: message,
        status: "error",
      });
    }
  };

  const onValueChangeHandler = (key, value) => {
    if (key === "otp") {
      setOtp(value);
    }
    if (key === "phone") {
      setPhone(value);
    }
  };

  return (
    <>
      <div className="row col-12 mb-3 d-flex justify-content-center">
        <div className="col-md-4 justify-content-center align-items-center d-flex">
          <Form onSubmit={onSubmit}>
            {!otpResponse && (
              <>
                <div>
                  <h1>Login</h1>
                </div>
                <div className={`row ${classes.container}`}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className={classes.label}>Email</Form.Label>
                    <Form.Control
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter Email"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label className={classes.label}>Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Enter Name"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className={classes.label}>
                      Phone Number
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={phone}
                      onChange={(e) =>
                        onValueChangeHandler("phone", e.target.value)
                      }
                      placeholder="Enter Phone Number"
                    />
                  </Form.Group>
                </div>
              </>
            )}

            {otpResponse && (
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className={classes.label}>
                  One Time Password (OTP)
                </Form.Label>
                <Form.Control
                  type="text"
                  value={otp}
                  onChange={(e) =>
                    onValueChangeHandler("otp", e.target.value)
                  }
                  placeholder="Enter the otp sent to your mobile number"
                />

                <div
                  className={`d-flex align-items-center justify-content-between mt-3`}
                >
                  <p onClick={onClearAll} className={classes.changeNumber}>
                    Change number?
                  </p>
                  <p
                    onClick={onResendOtp}
                    className={`${classes.resendButton} ${seconds > 0 && classes.disabled
                      }`}
                  >
                    Resend Otp {seconds > 0 ? `in ${seconds} seconds` : ""} ?
                  </p>
                </div>
              </Form.Group>
            )}

            <br />
            {loading && (
              <Button>
                <Spinner size="sm" className="mr-3" />
                PLEASE WAIT...
              </Button>
            )}
            {!loading && (
              <Button type="submit" className="mb-5">
                {" "}
                {otpResponse ? "VERIFY OTP" : "SEND OTP"}{" "}
              </Button>
            )}
          </Form>
        </div>
        <div className="d-none col-md-2 mt-2 justify-content-center align-items-center d-md-flex"><div className={classes.divider}> </div> </div>
        <div className="mb-5 col-md-4 d-flex align-items-center justify-content-center">
          {/* <h6 className="text-start mt-5">or</h6> */}
          <button className={classes.google_btn} onClick={googleAuth}>
            <img src="google.png" alt="google icon" />
            <span>Login with Google</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default Login;
