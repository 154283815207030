import React from 'react';

const TextAvatar = ({ text }) => {
    const stringToColor = (str) => {
        let hash = 0;
        let i;

        for (i = 0; i < str.length; i += 1) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }

        return color;
    };

    const backgroundColor = stringToColor(text);

    return (
        <div
            style={{
                backgroundColor,
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#fff',
                fontWeight: 'bold',
            }}
        >
            {text.split(' ')[0][0]}
        </div>
    );
};

export default TextAvatar;
