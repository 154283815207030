import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  CardFooter,
  Spinner,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Dashurl } from "../components/Baseurl";
import { getCurrencyLocaleString } from "../utility/helper";
import AOS from "aos";
import { Rate } from "antd";
import classes from "../components/product.module.css";
import { CartContext } from "../services/cart.context";
import { AuthenticationContext } from "../services/authentication.context";

const ProductCard = ({ product, index }) => {
  let {
    name,
    shortDescription,
    volume,
    rating,
    quantity,
    category,
    price,
    discount,
    finalPrice,
    images,
    averageRating,
    _id,
  } = product;

  let outOfStock = quantity === 0;
  let lessQuantity = quantity < 10;
  const navigate = useNavigate();
  const { cartItems, onAddCart } = useContext(CartContext);
  const { userData } = useContext(AuthenticationContext);
  const [loading, setLoading] = useState(false);

  const findExists = (id) => {
    let exists = cartItems.find((c) => c._id === id);
    return exists;
  };

  const inCart = findExists(_id);

  useEffect(() => {
    AOS.init();
  }, []);

  const onClickAddToCart = () => {
    if (!userData) {
      navigate("/login");
      return;
    }
    let data = {
      id: _id,
    };
    if (loading) {
      return;
    }
    setLoading(true);

    onAddCart(
      data,
      () => {
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };

  return (
    <Link to={`${product._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
      <Card
        // data-aos="fade-left"
        // data-aos-duration="800"
        // data-aos-offet="-20"
        // data-aos-delay={index * 100}
        className={classes.card}
      >
        <CardImg
          src={images[0]}
          alt={name}
          style={{
            height: 250,
            objectFit: "cover",
          }}
        />
        <CardBody>
          <div className="d-flex  justify-content-between w-100">
            <div>
              <CardText>{volume}</CardText>
              <CardTitle tag="h2">{name}</CardTitle>
              <p className={classes.category}>{category?.name}</p>
            </div>
            {discount ? <p className={classes.discount}>{discount}% off</p> : ""}
          </div>
          <Rate allowHalf disabled defaultValue={averageRating ? averageRating : rating} />
          <div className={classes.prices}>
            <h5>{getCurrencyLocaleString(finalPrice)}</h5>
            {discount ? (
              <p className={classes.originalPrice}>
                {getCurrencyLocaleString(price)}
              </p>
            ) : (
              ""
            )}
          </div>
          {lessQuantity && (
            <p
              className="text-danger mt-2"
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {outOfStock ? "Out of Stock" : `Only ${quantity} left in stock`}
            </p>
          )}
        </CardBody>
        {/* <CardFooter className={classes.bottomContainer}>
          <div className={`d-flex align-items-center justify-content-between `}>

          </div>
        </CardFooter> */}
      </Card>
    </Link>
  );
};

const ProductComponent = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    onGetCategories();
    onGetProducts();
  }, []);

  const onGetCategories = () => {
    axios
      .get(`${Dashurl}category`)
      .then((response) => {
        setCategories(response.data);
        // setBannerData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching banner data:", error);
      });
  };

  const onGetProducts = () => {
    axios
      .get(`${Dashurl}product`)
      .then(async (response) => {
        const products = await Promise.all(
          response.data.map(async (product) => {
            const response = await axios.get(`${Dashurl}average-rating/${product._id}`);
            const averageRating = response.data.averageRating || 0;
            return { ...product, averageRating };
          })
        );

        setProducts(products);
      })
      .catch((error) => {
        console.error("Error fetching products data:", error);
      });
  };

  return (
    <>
      <div className="row content-evenly bg-light">
        <img src="darkMustardOil.jpg" alt="Product" className="pb-5" />
        <div className="bg-light">
          <div className="container p-5">
            <div className="">
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to="/">Home</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>Products</BreadcrumbItem>
              </Breadcrumb>
            </div>
            <div className={classes.productCards}>
              {products.map((product, i) => (
                <ProductCard key={product._id} index={i} product={product} />
              ))}
            </div>
          </div>
        </div>
        <div className="container row">
          <div className={classes.imagecontainer}>
            <img src="/lowerimage.webp" alt="Product" className={classes.image} />
          </div>
          <div className={classes.textcontainer}>
            <p>At Greendumbells.com, we take pride in bringing you the purest essence of India's rich agricultural heritage our Cold-Pressed Kachi Ghani Mustard Oil. Our commitment to preserving tradition and honoring the hard work of our farmers is at the core of our values. The journey of our Mustard Oil begins with the dedicated efforts of Indian farmers who cultivate mustard seeds with unwavering passion and care. We believe in supporting their toil by hand-selecting the finest, non-GMO mustard seeds, ensuring that each drop of oil is a tribute to their dedication. Our Cold- Pressed technique preserves the oil's natural purity, retaining the rich aroma and the distinct flavor that generations have cherished. This process not only guarantees superior taste but also ensures that you receive the countless health benefits that this oil has been renowned for. Experience the heartfelt tradition, health, and purity of India's Mustard Oil with Greendumbells.com. Embrace the legacy.</p>
          </div>
        </div>

      </div>
    </>
  );
};

export default ProductComponent;
