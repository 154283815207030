import { useContext, useEffect, useState } from "react";
import { CartContext } from "../services/cart.context";
import classes from "../components/Cart.module.css";
import { getCurrencyLocaleString } from "../utility/helper";
import Swal from "sweetalert2";
import { AuthenticationContext } from "../services/authentication.context";
import { Link, useNavigate } from "react-router-dom";
import { Button, Card, Form, Modal, Spinner } from "react-bootstrap";
import "./Cart.css";

const CartComponent = () => {
  const { cartItems, onRemoveCart, onCheckout, coupons, onChangeQuantity, onAddAddress } =
    useContext(CartContext);
  const { userData } = useContext(AuthenticationContext);
  const [cart, setCart] = useState([]);
  const navigate = useNavigate();
  const [prices, setPrices] = useState({
    subTotal: 0,
    total: 0,
    shipping: 0,
  });
  const [addressModal, setAddressModal] = useState(null);
  // const [inputs, setInputs] = useState(defaultInputState);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [isCouponModalOpen, setIsCouponModalOpen] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [expiredMessage, setExpiredMessage] = useState('');
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [itemQuantities, setItemQuantities] = useState({});

  // {
  //   name: "sunil kumar pasupuleti",
  //   phone: 9959907940,
  //   pincode: 520010,
  //   state: "Andhra Pradesh",
  //   city: "Vijayawada",
  //   houseNum: "70-2-30/A",
  //   area: "Shiva shnkar nagar, thoatvari street",
  //   landmark: "Near viswa bharati school donak road",
  // }

  useEffect(() => {
    if (!userData) {
      navigate("/");
    }
  }, [userData]);

  useEffect(() => {
    if (cartItems) {
      onSetCartItems();
    }
  }, [cartItems]);

  useEffect(() => {
    if (userData.address) {
      setAddresses(userData.address)
    }
  }, [userData? userData:[]]);

  useEffect(() => {
    if (cart) {
      onCalculatePrices();
    }
  }, [cart]);

  const onSetCartItems = () => {
    let structured = [];
    let totalQuantity = 0;

    cartItems.forEach((cartItem) => {
      if (cartItem && cartItem.product) {
        const { product, quantity } = cartItem;
        const { _id, name, quantity: maxQuantity } = product;

        if (structured[_id]) {
          structured[_id].orderQuantity += quantity;
        } else {
          structured[_id] = { ...product, orderQuantity: quantity, name, maxQuantity };
        }

        totalQuantity += quantity;
      }
    });

    setCart(Object.values(structured));
    setItemQuantities(structured);
    setTotalQuantity(totalQuantity);
  };

  const IncreaseQuantity = (index) => {
    let currentCartItems = [...cart];
    let cartItem = currentCartItems[index];
    if (cartItem.orderQuantity >= cartItem.maxQuantity) {
      return;
    }
    cartItem.orderQuantity++;
    currentCartItems[index] = cartItem;
    setCart(currentCartItems);
    setTotalQuantity((prevTotalQuantity) => prevTotalQuantity + 1);
    onChangeQuantity(cartItem._id, 1)
  };

  const DecreaseQuantity = (index) => {
    let currentCartItems = [...cart];
    let cartItem = currentCartItems[index];
    if (cartItem.orderQuantity <= 1) {
      return;
    }
    cartItem.orderQuantity--;
    currentCartItems[index] = cartItem;
    setCart(currentCartItems);
    setTotalQuantity((prevTotalQuantity) => prevTotalQuantity - 1);
    onChangeQuantity(cartItem._id, -1)
  };

  const toggleCouponModal = () => {
    setIsCouponModalOpen(!isCouponModalOpen);
  };

  const applyCoupon = (coupon) => {
    const currentDate = new Date();

    if (coupon && coupon.expiry) {
      const couponExpiryDate = new Date(coupon.expiry);

      if (currentDate <= couponExpiryDate) {
        setSelectedCoupon(coupon);
        setExpiredMessage('');
      } else {
        // alert('This coupon has expired.');
        setExpiredMessage('This coupon has expired.');
      }
    } else {
      setSelectedCoupon(coupon);
      setExpiredMessage('');
    }
    toggleCouponModal();
    // onCalculatePrices();
  };

  const removeCoupon = () => {
    setSelectedCoupon(null);
    // toggleCouponModal();
  };

  const onCalculatePrices = () => {
    let subTotal = 0;
    let shipping = 0;
    let total = 0;
    let discount = 0;

    cart.forEach((c) => {
      subTotal += c.orderQuantity * c.finalPrice;
    });

    if (selectedCoupon) {
      if (selectedCoupon.discount < 100) {
        discount = (subTotal * selectedCoupon.discount) / 100;

        if (discount > selectedCoupon.maxDiscount) {
          discount = selectedCoupon.maxDiscount;
        }
      } else {
        discount = subTotal;
      }
    }

    total = subTotal - discount;

    if (total === 0) {
      shipping = 0;
    } else {
      if (total < 600) {
        shipping = 70;
      } else {
        shipping = 0;
      }
    }

    total = total + shipping;

    setPrices({
      subTotal: subTotal,
      shipping: shipping,
      total: total,
    });
  };

  useEffect(() => {
    onCalculatePrices();
  }, [selectedCoupon]);

  const onRemoveFromCart = (id) => {
    Swal.fire({
      title: "Do you want to remove the item from cart?",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        onRemoveCart(id, () => { });
      }
    });
  };

  const onClickCheckout = () => {
    if (!address) {
      setAddressModal(true);
      return;
    }
    let data = {
      prices: prices,
      address: address,
      items: cart,
    };
    setLoading(true);
    onCheckout(
      data,
      (result) => {
        setAddress(null);
        setLoading(false);
      },
      () => {
        setAddress(null);
        setLoading(false);
      }
    );
  };

  const onCloseModal = () => {
    setAddressModal(false);
  };

  useEffect(() => {
    if (address) {
      onClickCheckout();
    }
  }, [address]);

  const handleSelectAddress = (address) => {
    setAddress(address);
    onCloseModal();
  };

  const getFormattedAddress = (address) => {
    let formattedAddress = `${address.houseNum}, ${address.area} ${address.landmark ? ", " + address.landmark : ""
      }, ${address.city}, ${address.state} -  ${address.pincode}`;
    return formattedAddress;
  };

  return (
    <div className={classes.container}>
      <h4>Shopping Cart</h4>
      <Modal show={isCouponModalOpen} onHide={toggleCouponModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Coupons</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {coupons.length === 0 ? (
            <p>No coupons available.</p>
          ) : (
            coupons.map((coupon) => (
              <div key={coupon.name} className="d-flex align-items-center justify-content-between mb-2">
                <div>
                  {coupon.name} - {coupon.discount}% off
                </div>
                <Button variant="primary" onClick={() => applyCoupon(coupon)} style={{ width: "10rem" }}>
                  Apply
                </Button>
              </div>
            ))
          )}
        </Modal.Body>
      </Modal>

      <div className={classes.project}>
        <div className={classes.shop}>
          {cart.map((item, index) => {
            let {
              name,
              _id,
              images,
              finalPrice,
              discount,
              price,
              orderQuantity,
            } = item;
            return (
              <div className={classes.box}>
                <img src={images[0]} alt="..." />
                <div className={classes.content}>
                  <h4>{name}</h4>
                  <div className={classes.prices}>
                    <h5 className={classes.finalPrice}>
                      {" "}
                      {getCurrencyLocaleString(finalPrice)}
                    </h5>

                    {discount > 0 ? (
                      <h5 className={classes.price}>
                        {" "}
                        {getCurrencyLocaleString(price)}
                      </h5>
                    ) : null}
                    {discount > 0 ? (
                      <h5 className={classes.discount}>{discount}% off</h5>
                    ) : null}
                  </div>
                  <div className={classes.unit}>
                    <div
                      className={classes.minus}
                      onClick={() => DecreaseQuantity(index)}
                    >
                      <i aria-hidden="true" className="fa fa-minus"></i>{" "}
                    </div>
                    <div className={classes.unitQuantity}>{orderQuantity}</div>
                    <div
                      className={classes.plus}
                      onClick={() => IncreaseQuantity(index)}
                    >
                      <i aria-hidden="true" className="fa fa-plus"></i>{" "}
                    </div>
                  </div>
                  <p
                    className={classes["btn-area"]}
                    onClick={() => onRemoveFromCart(_id)}
                  >
                    <i aria-hidden="true" className="fa fa-trash"></i>{" "}
                    <span className={classes.btn2}>Remove</span>
                  </p>
                </div>
              </div>
            );
          })}
        </div>

        <div className={classes["right-bar"]}>
          <div>
            <p>
              <span>Subtotal</span>{" "}
              <span>{getCurrencyLocaleString(prices.subTotal)}</span>
            </p>
            <hr />
            <p>
              <span>Total Different Items</span> <span>{cart.length}</span>
            </p>
            <hr />
            <p>
              <span>Total Quantity</span> <span>{totalQuantity}</span>
            </p>
            <ul>
              {Object.values(itemQuantities).map((item) => (
                <li key={item._id}>
                  {item.name} - {item.orderQuantity}
                </li>
              ))}
            </ul>
            <hr />
            <p>
              <span>Shipping</span>{" "}
              <span>{getCurrencyLocaleString(prices.shipping)}</span>
            </p>
            <hr />
            <div className="d-flex flex-row justify-content-between">
              {selectedCoupon && !expiredMessage ? (
                <p style={{ fontSize: "1rem" }}>
                  {selectedCoupon.name} - {selectedCoupon.discount}% off
                </p>
              ) : (
                <p style={{ fontSize: "1rem", color: "red" }}>
                  {expiredMessage || "No coupon applied"}
                </p>
              )}

              {selectedCoupon ? (
                <Button
                  style={{ width: "6rem", height: "2rem" }}
                  onClick={removeCoupon}
                >
                  Remove
                </Button>
              ) : (
                <Button
                  style={{ width: "6rem", height: "2rem" }}
                  onClick={toggleCouponModal}
                >
                  {expiredMessage ? "Expired" : "Apply"}
                </Button>
              )}
            </div>
            <p>
              <span>Total</span>{" "}
              <span>{getCurrencyLocaleString(prices.total)}</span>
            </p>
          </div>

          {loading ? (
            <Button>
              <Spinner size="sm" className="mr-3" />
              PLEASE WAIT...
            </Button>
          ) : (
            <Button onClick={onClickCheckout}>
              <i className="fa fa-shopping-cart"></i>
              Checkout
            </Button>
          )}

          <br />
          <small className="text-muted">
            Please don't close the window or browser during payment
          </small>
        </div>

      </div>

      <Modal size="lg" show={addressModal} onHide={onCloseModal} centered>
        <Modal.Header closeButton className="custom-close-button">
          <div className="delivery_address">
            <Modal.Title className="title">Select Delivery Address</Modal.Title>
          </div>
        </Modal.Header>

        <Modal.Body>
          {addresses && addresses.map((add) => (
            <Card key={add._id} className="mt-2 mb-4">
              <Card.Body>
                <Card.Title>
                  Delivery to - {add.name}, {add.pincode}
                </Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  {add.phone}
                </Card.Subtitle>
                <Card.Text>{getFormattedAddress(add)}</Card.Text>
                <p
                  onClick={() => handleSelectAddress(add)}
                  style={{
                    color: "blue",
                    cursor: "pointer",
                  }}
                >
                  Select Address
                </p>
              </Card.Body>
            </Card>
          ))}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" className="submit-btn">
            <Link to="/address" style={{ textDecoration: 'none', display: 'inline-block', color: 'inherit' }}>
              <div>Add Address</div>
            </Link>
          </Button>

          <Button className="submit-btn1" onClick={onCloseModal} style={{ border: "2px solid #000" }}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CartComponent;