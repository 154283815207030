import React from "react";

function DeleteData({ darkMode }: any) {
  return (
    <div className={darkMode ? "text-light bg-dark" : "text-dark bg-light"}>
      <article className="container py-3">
        <h1>Delete Your Data</h1>
          <p className="lead">To delete all your data kindly write an email to info@greendumbells.com and your data will be deleted in 2 working days. Thank You for using our services.</p>
        </article>
    </div>
  );
}

export default DeleteData;
