import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Rate } from "antd";
import Slider from "react-slick";
import { Dashurl } from "./Baseurl";
import "./Testimonial.css";

function Testimonials() {
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await fetch(`${Dashurl}testimonials`);
        if (response.ok) {
          const data = await response.json();
          setTestimonials(data);
        } else {
          console.error("Error:", response.status);
        }
      } catch (err) {
        console.error("Error:", err);
      }
    };

    fetchTestimonials();
  }, []);

  // Configure the settings for the carousels
  const carouselSettingsLeftToRight = {
    dots: false,
    infinite: true,
    speed: 4600, // Adjust the speed as needed (lower value for slower speed)
    slidesToShow: 4, // Display four testimonials on larger screens
    slidesToScroll: 1, // Scroll one testimonial at a time
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Set autoplay speed to 0 to make it continuous
    cssEase: "linear", // Use linear easing for a continuous effect
    pauseOnHover: false, // Do not pause on hover
    rtl: false, // Move from left to right
    responsive: [
      {
        breakpoint: 768, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 1, // Display two testimonials on smaller screens (mobile)
        },
      },
    ],
  };


  const carouselSettingsRightToLeft = {
    dots: false,
    infinite: true,
    speed: 4600, // Adjust the speed as needed (lower value for slower speed)
    slidesToShow: 4, // Display four testimonials on larger screens
    slidesToScroll: 1, // Scroll one testimonial at a time
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Set autoplay speed to 0 to make it continuous
    cssEase: "linear", // Use linear easing for a continuous effect
    pauseOnHover: false, // Do not pause on hover
    rtl: true, // Move from right to left (opposite direction)
    responsive: [
      {
        breakpoint: 768, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 1, // Display two testimonials on smaller screens (mobile)
        },
      },
    ],
  };
  

  return (
    <section className="testimonials">
      
        <h2>TESTIMONIALS</h2>       
          <div className="row col-12">
          <div className="container">
            <div className="testimonial-carousel-container">
              <Slider {...carouselSettingsLeftToRight} className="testimonial-carousel">
                {testimonials.map((item, index) => (
                  <div key={index} className="testimonial-card-container">
                    <div className="testimonial-card">
                      <div className="profile">
                        <img className="user" src={`${Dashurl}${item.image}`} alt={item.id} />
                        <Rate allowHalf disabled defaultValue={item.rating} />
                        <p>{item.description}</p>
                        <h3>{item.name}</h3>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
              <Slider {...carouselSettingsRightToLeft} className="testimonial-carousel-right">
                {testimonials.map((item, index) => (
                  <div className="testimonial-card-container" key={index}>
                    <div className="testimonial-card">
                      <div className="profile">
                        <img className="user" src={`${Dashurl}${item.image}`} alt={item.id} />
                        <Rate allowHalf disabled defaultValue={item.rating} />
                        <p>{item.description}</p>
                        <h3>{item.name}</h3>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
    </section>
  );
}

export default Testimonials;
